import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    ElementRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { isCurrentUserMessage } from '../message.service';
import { Message, MessageReadStatus } from '../messaging.types';

@Component({
    selector: 'scp-message-list',
    templateUrl: './message-list.component.html',
    styleUrls: ['./message-list.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageListComponent implements OnChanges, AfterViewInit {
    @Input() messages: Message[];
    @Input() currentUser: Message['author'];
    @Input() type: Message['type'] = 'internal';
    /**
     * @hidden no one see read status
     * @markable manual mark read status
     * @visible see read status of incomming and outcomming messages, mark new messages as read on start
     */
    @Input() readStatus: MessageReadStatus = 'hidden';

    @Output() markAsRead = new EventEmitter<Message[]>();
    @Output() newMessageDetected = new EventEmitter<Message>();

    newMessages: Message[] = [];
    lastMessage = null;

    constructor(private elementRef: ElementRef) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.messages &&
            changes.messages.currentValue !== changes.messages.previousValue &&
            changes.messages.currentValue
        ) {
            const currentLastMessage = this.messages.length > 0 ? this.messages[this.messages.length - 1] : null;

            if (!this.lastMessage || (currentLastMessage && currentLastMessage.id !== this.lastMessage.id)) {
                this.newMessageDetected.emit(!this.lastMessage ? null : currentLastMessage);
                // if new message then scroll down
                requestAnimationFrame(() => {
                    this.scrollToBottom();
                });
            }

            this.lastMessage = currentLastMessage;
        }
    }

    public ngAfterViewInit(): void {
        this.scrollToBottom();
    }

    public isCurrentUserMessage(message: Message) {
        return isCurrentUserMessage(message, this.type, this.currentUser);
    }

    public isCurrentMessageOurs(message: Message) {
        if (this.type === 'external') {
            return message.authorRole !== 'customer' && message.authorSubrole !== 'customer';
        }
        return false;
    }

    public changeReadStatus(message: Message) {
        this.markAsRead.emit([message]);
    }

    private scrollToBottom() {
        // scroll to the latest messages
        this.elementRef.nativeElement.scrollTop =
            this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.clientHeight;
    }
}
