import { TaskType, TaskState } from './../../../../../shared/types/task.types';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-state-icon',
    templateUrl: './state-icon.component.html',
    styleUrls: ['./state-icon.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateIconComponent {
    @Input()
    state: TaskState;

    @HostBinding('attr.self-state')
    get getAttrState() {
        return this.state === this.compositeState ? null : this.state;
    }

    @Input()
    @HostBinding('attr.state')
    compositeState: TaskState;

    @Input()
    @HostBinding('attr.type')
    type: TaskType;

    @HostBinding('attr.title')
    get getTitle() {
        return (
            this.type +
            ': ' +
            (this.state === this.compositeState ? this.state : this.compositeState + ' / ' + this.state)
        );
    }

    constructor() {}
}
