<span
    class="icon"
    *ngIf="icon"
>
    <ion-icon
        class="main"
        name="{{icon}}"
    ></ion-icon>
    <ion-icon
        class="glow"
        name="{{icon}}"
    ></ion-icon>
    <ion-icon
        class="highlight"
        name="{{icon}}"
    ></ion-icon>
</span>
<span class="inner-text">
    <ng-content></ng-content>
    <ion-icon
        *ngIf="checked"
        name="checkmark-outline"
        class="checked-icon"
    ></ion-icon>
</span>