import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';

if (environment.production) {
    enableProdMode();

    console.log = () => {};

    Sentry.init({
        dsn: 'https://fc537555f85048ca9714ddf08eff5999@o544272.ingest.sentry.io/5665383',
        release: '0.0.1',
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['https://client.stylers.cloud'],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

mixpanel.init('a68aa549899906b620cc72c4aa04efb1', { debug: !environment.production });
mixpanel.register_once({
    sc_app: 'client',
});
mixpanel.track('init');

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
