import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['./modal.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
    @Input() showCloseBtn = true;
    @Output() close = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}
}
