import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, map, retryWhen, share, take, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { MessageSenderService } from './message-sender.service';
import { Credentials, JwtToken } from '../../../shared/types/auth.types';
import { Response } from '../../../shared/types/http-response.types';
import { ActionTypes } from '../../../shared/types/message.types';
import { SubRoles, User, UserRoles } from '../../../shared/types/user.types';
import jwt_decode from 'jwt-decode';
import { TrackingService } from './tracking.service';
import { loadRefreshToken } from '../helpers/refresh-token';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _accessToken = '';
    private refreshTokenResponse$: Observable<Response<Pick<JwtToken, 'accessToken' | 'accessTokenExpiresIn'>>>;

    private currentUser: User = null;

    constructor(
        private http: HttpClient,
        private messageSenderService: MessageSenderService,
        private track: TrackingService,
    ) {}

    private set accessToken(accessToken: string) {
        this._accessToken = accessToken;

        if (accessToken === '') {
            this.messageSenderService.post({ action: ActionTypes.userLoggedOut });
        } else {
            const decodedToken = decodeAccessToken(accessToken);
            this.currentUser = {
                roles: decodedToken.roles,
                subroles: decodedToken.subroles,
                id: decodedToken.sub,
                nickname: null,
                email: null,
            };
            this.track.identifyUserId(this.currentUser.id);

            this.messageSenderService.post({ action: ActionTypes.userLoggedIn, accessToken });
        }
    }

    private get accessToken() {
        return this._accessToken;
    }

    public getCurrentUser() {
        return this.currentUser;
    }

    public getAccessToken() {
        return this.accessToken;
    }

    public isLoggedIn() {
        if (this.accessToken) {
            return of(true);
        } else {
            try {
                const refreshToken = loadRefreshToken();
                return refreshToken
                    ? this.refreshAccessTokenUsingRefreshToken(refreshToken).pipe(map(x => !!x?.data.accessToken))
                    : of(false);
            } catch {
                return of(false);
            }
        }
    }

    public logIn(credentials: Credentials) {
        return this.http.post<Response<JwtToken>>(environment.apiUrl + 'v1/auth/login', credentials).pipe(
            tap(response => {
                this.accessToken = response.data.accessToken;
                localStorage.setItem('refreshToken', response.data.refreshToken);
                this.setRefreshTokenTimer(response.data.accessTokenExpiresIn);
            }),
        );
    }

    public logOut() {
        // TODO remove from all sesions
        return this.http
            .post<Response<{ message: string }>>(environment.apiUrl + 'v1/auth/logout', null)
            .pipe(tap(() => this.logOutLocal()));
    }

    public logOutLocal() {
        this.accessToken = '';
        localStorage.removeItem('refreshToken');
        this.track.logout();
    }

    public waitRefreshToken(): Observable<void> {
        const refreshToken = loadRefreshToken();
        if (refreshToken) {
            return this.refreshAccessTokenUsingRefreshToken(refreshToken).pipe(map(() => {}));
        }
        return throwError(new Error('refreshToken not available'));
    }

    private refreshAccessTokenUsingRefreshToken(
        refreshToken: string,
    ): Observable<Response<Pick<JwtToken, 'accessToken' | 'accessTokenExpiresIn'>>> {
        if (this.refreshTokenResponse$) {
            return this.refreshTokenResponse$;
        } else {
            if (!this.currentUser) {
                try {
                    const decodedToken = decodeAccessToken(refreshToken);
                    this.currentUser = {
                        ...this.currentUser,
                        id: decodedToken.sub,
                    };
                } catch (e) {
                    localStorage.removeItem('refreshToken');
                    throw e;
                }
            }

            this.refreshTokenResponse$ = this.http
                .post<Response<Pick<JwtToken, 'accessToken' | 'accessTokenExpiresIn'>>>(
                    environment.apiUrl + 'v1/auth/refresh',
                    {
                        refreshToken,
                    },
                )
                .pipe(
                    retryWhen(errors => {
                        return errors.pipe(
                            tap(error => {
                                if (error.status == 429) {
                                    return;
                                }

                                if (400 <= error.status && error.status < 500) {
                                    throw error;
                                }
                            }),
                            take(3),
                            delay(1000),
                        );
                    }),
                    tap(response => {
                        this.accessToken = response.data.accessToken;
                        this.setRefreshTokenTimer(response.data.accessTokenExpiresIn);
                        this.refreshTokenResponse$ = null;
                    }),
                    share(),
                );

            return this.refreshTokenResponse$;
        }
    }

    private setRefreshTokenTimer(accessTokenExpiresIn: number) {
        const secondsBeforeExpiration = 60;
        const refreshToken = loadRefreshToken();
        setTimeout(() => {
            this.refreshAccessTokenUsingRefreshToken(refreshToken);
        }, accessTokenExpiresIn - secondsBeforeExpiration);
    }
}

function decodeAccessToken(accessToken: string) {
    return jwt_decode<DecodedAccessToken>(accessToken);
}

export interface DecodedAccessToken {
    sub: string;
    iss: string;
    aud: string;
    exp: number;
    iat: number;
    token_use: string;
    roles: UserRoles[];
    subroles: SubRoles[];
    logout_id: string;
}
