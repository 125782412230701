import { Pipe, PipeTransform } from '@angular/core';
import { ModificationState } from '../../../../../shared/types/modification.types';

@Pipe({
    name: 'isOneOfModificationStates',
})
export class IsOneOfModificationStatesPipe implements PipeTransform {
    transform(state: ModificationState, allowedStates: ModificationState[]) {
        return allowedStates.includes(state);
    }
}
