import { Component, OnInit, ViewEncapsulation, HostListener, Input } from '@angular/core';
import { MessageSenderService } from 'src/app/services/message-sender.service';
import { ActionTypes } from '../../../../shared/types/message.types';

@Component({
    selector: 'app-ui-close-button',
    templateUrl: './close-button.component.html',
    styleUrls: ['./close-button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ClientCloseButtonComponent implements OnInit {
    @Input() emboss = true;
    @Input() ignoreClick = false;

    constructor(private message: MessageSenderService) {}

    ngOnInit() {}

    onCloseButtonClick() {
        if (!this.ignoreClick) {
            this.message.post({ action: ActionTypes.appClose });
        }
    }
}
