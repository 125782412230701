import { SupportModeGuard } from './services/support-mode.guard';
import { Injectable, NgModule } from '@angular/core';
import { CanActivate, CanActivateChild, PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PreviousRouteService } from './services/previous-route.service';
import { ProjectService } from './services/project.service';

@Injectable({
    providedIn: 'root',
})
export class InitAuthGuardService implements CanActivate {
    constructor(public auth: AuthService, public router: Router) {}

    canActivate(): Observable<boolean> {
        return this.auth.isLoggedIn().pipe(
            tap(isLoggedIn => {
                if (!isLoggedIn) {
                    this.router.navigate(['login']);
                }
            }),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivateChild, CanActivate {
    constructor(public auth: AuthService, public router: Router, private previousRouteService: PreviousRouteService) {}

    canActivate(): Observable<boolean> {
        return this.auth.isLoggedIn().pipe(
            tap(isLoggedIn => {
                if (isLoggedIn) {
                    const previousRoute = this.previousRouteService.getPreviousRoute();

                    // this is because app back button can get you to login and that creates redirect loop
                    if (previousRoute && previousRoute !== '/login') {
                        this.router.navigate([previousRoute]);
                    } else {
                        this.router.navigate(['home']);
                    }
                }
            }),
            map(isLoggedIn => !isLoggedIn),
        );
    }

    canActivateChild(): Observable<boolean> {
        return this.auth.isLoggedIn().pipe(
            tap(isLoggedIn => {
                if (!isLoggedIn) {
                    this.router.navigate(['login']);
                }
            }),
        );
    }
}

const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuardService],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
            },
            {
                path: 'tasks/new',
                loadChildren: () => import('./pages/new-task/new-task.module').then(m => m.NewTaskPageModule),
                resolve: { project: ProjectService },
            },
            {
                path: 'tasks',
                loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksPageModule),
                resolve: { project: ProjectService },
            },
            {
                path: 'report-issue',
                loadChildren: () =>
                    import('./pages/report-issue/report-issue.module').then(m => m.ReportIssuePageModule),
                resolve: { project: ProjectService },
            },
            {
                path: 'showcase',
                loadChildren: () => import('./pages/showcase/showcase.module').then(m => m.ShowcasePageModule),
            },
            {
                path: 'designs',
                canActivate: [SupportModeGuard],
                loadChildren: () => import('./pages/designs/designs.module').then(m => m.DesignsPageModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
            },
            {
                path: 'styles',
                loadChildren: () => import('./pages/styles/styles.module').then(m => m.StylesPageModule),
                resolve: { project: ProjectService },
            },
            {
                path: 'modifications',
                loadChildren: () =>
                    import('./pages/modifications/modifications.module').then(m => m.ChangeRequestsPageModule),
                resolve: { project: ProjectService },
            },
            {
                path: 'modification-diff',
                loadChildren: () =>
                    import('./pages/modification-diff/modification-diff.module').then(
                        m => m.ModificationDetailPageModule,
                    ),
                resolve: { project: ProjectService },
            },
            {
                path: 'task-modifications',
                loadChildren: () =>
                    import('./pages/task-modifications/task-modifications.module').then(
                        m => m.TaskModificationsPageModule,
                    ),
                resolve: { project: ProjectService },
            },
        ],
    },
    {
        path: 'login',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'init',
        data: { initialize: true },
        canActivate: [InitAuthGuardService],
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    },
];
// https://www.joshmorony.com/converting-ionic-3-push-pop-navigation-to-angular-routing-in-ionic-4/

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
