import {
    Directive,
    ElementRef,
    HostListener,
    EventEmitter,
    Output,
} from '@angular/core'; /*
    Myth busters - Ionic 3

    Urobil som taky maly vyskum eventov (click) a (tap), lebo sa siria myty, ze treba pridavat atribut
    "tappable" na vsetky ine elementy ako button, lebo inak bude delay na (click) cca 300ms.

    Co som zistil:
    S "tappable" atributom (tap) to (click) cca 5ms.
    S "tappable" atributom (tap) to (click) cca 5ms (cize ziadny rozdiel - podotykam ze <div> element.)
    Rozdiel medzi (tap) a (click) je aj ten, ze pri trosku dlhsom podrzani touchu sa tap neodpali, ale click ano.

    Este je aj (press) event, ale to je taky hybrid medzi (tap) a (click) ´pri velmi kratkom dotyku (tap) sa
    neodpali, treba podrzat dlhsie (ale odpali sa potom sam aj bez releasu prstu cca po 270-280ms).

    ```if you are making mobile apps (tap) might be better. This is because when using (click) the action always
    executes, even when tapping accidentally. The (tap) won't execute if the user holds it for a longer period.
    And if you want to have a button that needs to be clicked for a longer period of time you can use the (press).```

    Cize 300ms delay je uz neaktualny (tzv. mytus):
    ```The classic 300ms delay thankfully hasn't been an issue in Ionic for a very long time. It was resolved and
    good bit before the release of Ionic 1 Final.```

    Rozdiel od (touchstart) po (tap) je cca 30-270ms - podla toho, ako dlho bol podrzany prst, ale *ak presiahnem 270ms,
    tak sa uz (tap) neodpali* a automaticky sa odpali (press).

    Ked touch presiahne cca 700ms, neodpali sa ani (click), iba (press), ale ten sa odpalil uz po 270-280ms.

    Vysledok - ja osobne odporucam (click), lebo je funkcnejsi (pri trosku dlhsom podrzani) stale funguje
    a netreba uz pouzivat atribut "tappable"*. (edited)
 */

/**
 * Vraj (click) - jeho vykonanie, je na iOS oddialene, lebo caka ci nebude nahodou double click.
 *
 * (tap) a (press) ma tiez svoje muchy:
 */ @Directive({
    selector: '[klik]', // Attribute selector
})
export class KlikDirective {
    // when touchend
    /* tslint:disable:no-unused-variable */
    private static lastTouchEnd = 0; // timestamp
    /* tslint:enable:no-unused-variable */

    constructor(private elementRef: ElementRef) {}

    // --------------------------------------------------------------------------
    // Public interface and business logic --------------------------------------
    // --------------------------------------------------------------------------

    @Output()
    klik = new EventEmitter();

    // ngOnInit() {
    // }

    // ngOnDestroy() {
    // }

    // --------------------------------------------------------------------------
    // Private business logic ---------------------------------------------------
    // --------------------------------------------------------------------------

    // when touchstart
    private when = 0; // timestamp

    // start touch/click position
    private startPos = { x: 0, y: 0 };

    // time diff tolerance (from touchstart to touchend)
    private TIME_DIFF_TOLERANCE = 700; // ms

    // position diff tolerance (from touchstart to touchend)
    private POSITION_DIFF_TOLERANCE = 32; // px

    /**
     * When touch on touch device, it simulates also mousedown and mouseup (and other mouse event...)
     * Order:
     *   1) touchstart
     *   2) touchend
     *   3) mousedown (with same timeStamp as touchend)
     *   4) mouseup (with same timeStamp as touchend)
     *
     * So when touch, we ignore simulated mouse events.
     */

    // max delay between touchend and mousedown (when browser simualtes mouse events on touch event)
    private MAX_DELAY = 1000; // ms (1sec between real touch and real click - if some device support both is enough)

    @HostListener('touchstart', ['$event'])
    /* tslint:disable:no-unused-variable */
    private onTouchStart(event) {
        const now = Date.now();
        this.when = now;
        this.startPos = getPosition(event);

        // console.log(event.type, now, event.timeStamp, this.startPos, event);
    }
    /* tslint:enable:no-unused-variable */

    @HostListener('mousedown', ['$event'])
    /* tslint:disable:no-unused-variable */
    private onMouseDown(event) {
        // when original event was touch, we ignore simulated mouse events
        /* tslint:disable-next-line:no-string-literal */
        if (event.timeStamp - this.constructor['lastTouchEnd'] < this.MAX_DELAY) {
            // update last time
            /* tslint:disable-next-line:no-string-literal */
            this.constructor['lastTouchEnd'] = event.timeStamp;
            return;
        }
        const now = Date.now();
        this.when = now;
        this.startPos = getPosition(event);

        // console.log(event.type, now, event.timeStamp, this.startPos, event);
    }
    /* tslint:enable:no-unused-variable */

    @HostListener('touchend', ['$event'])
    /* tslint:disable:no-unused-variable */
    private onTouchEnd(event) {
        // we dont want to block touchend (in onKlikEnd method) it self when doubleclick
        /* tslint:disable-next-line:no-string-literal */
        this.constructor['lastTouchEnd'] = 0;

        // call common functionality for `touchend` and `mouseup`
        this.onKlikEnd(event);
        /* tslint:disable-next-line:no-string-literal */
        this.constructor['lastTouchEnd'] = event.timeStamp;
    }
    /* tslint:enable:no-unused-variable */

    @HostListener('mouseup', ['$event'])
    /* tslint:disable:no-unused-variable */
    private onMouseUp(event) {
        // call common functionality for `touchend` and `mouseup`
        this.onKlikEnd(event);
    }
    /* tslint:enable:no-unused-variable */

    // common functionality for `touchend` and `mouseup`
    private onKlikEnd(event) {
        // when bubbling and canceled bubbling (via event.stopPropagation() method)
        /* tslint:disable-next-line:no-string-literal */
        if (event.timeStamp - this.constructor['lastTouchEnd'] < this.MAX_DELAY || event.klikCancelBubble === true) {
            return;
        }

        const now = Date.now();
        const time = now - this.when;
        const pos = getPosition(event);
        const diff = getPositionDiff(this.startPos, pos);

        // console.log(event.type, now, event.timeStamp, pos, diff, event);

        // emit event
        if (diff <= this.POSITION_DIFF_TOLERANCE && time <= this.TIME_DIFF_TOLERANCE) {
            // timeout 0ms because some other effects like :active preudo class is not presenting when not timeouted
            setTimeout(() => {
                // if event.stopPropaggation() was not called
                if (event.cancelBubble === false && event.klikCancelBubble !== true) {
                    const customEvent = this.createCustomEvent();
                    this.klik.emit(customEvent);
                    // if canceled bubbling
                    if (customEvent.cancelBubble) {
                        event.klikCancelBubble = true;
                    }
                }
            }, 0);
        }
    }

    private createCustomEvent() {
        const customEvent = new MouseEvent('klik', { bubbles: true, cancelable: true, view: window });
        const target = this.elementRef.nativeElement;
        Object.defineProperty(customEvent, 'target', { value: target, enumerable: true });
        Object.defineProperty(customEvent, 'srcElement', { value: target, enumerable: true });
        // som fixes because iOS sucks
        /* tslint:disable-next-line:no-string-literal */
        customEvent['originalStopPropagation'] = customEvent.stopPropagation;
        customEvent.stopPropagation = function () {
            /* tslint:disable-next-line:no-string-literal */
            customEvent['originalStopPropagation']();
            this.cancelBubble = true;
            this.klikCancelBubble = true;
        };
        return customEvent;
    }
}

// --------------------------------------------------------------------------
// Helper functions ---------------------------------------------------------
// --------------------------------------------------------------------------

function getPosition(event) {
    if (event.changedTouches) {
        return {
            x: event.changedTouches[0].clientX,
            y: event.changedTouches[0].clientY,
        };
    } else {
        return {
            x: event.clientX,
            y: event.clientY,
        };
    }
}

function getPositionDiff(pos1, pos2) {
    return Math.abs(pos1.x - pos2.x) + Math.abs(pos1.y - pos2.y);
}
