import { Urgency } from '../../../shared/types/task.types';
export { urgencyLabels, urgencyDeliveryTimeLabels } from '../../../shared/strings/urgency';

// Urgency long texts are in new-task.page.html

export const urgencySmallIcons = {
    [Urgency.low]: '/assets/images/icon-urgency-very-low-small.png',
    [Urgency.normal]: '/assets/images/icon-urgency-normal-small.png',
    [Urgency.urgent]: '/assets/images/icon-urgency-urgent-small.png',
    [Urgency.asap]: '/assets/images/icon-urgency-asap-small.png',
};

const MINUTE_IN_MS = 1000 * 60;
const DAY_IN_MS = MINUTE_IN_MS * 60 * 24;
export const urgencyDeliveryTime = {
    [Urgency.low]: 10 * DAY_IN_MS, // should be workdays
    [Urgency.normal]: 5 * DAY_IN_MS, // should be workdays
    [Urgency.urgent]: 3 * DAY_IN_MS,
    [Urgency.asap]: DAY_IN_MS,
};
