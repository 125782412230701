import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { Response } from '../../../shared/types/http-response.types';
import { UserDetail } from '../../../shared/types/user.types';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(private http: HttpClient) {}
    getUserDetail(userId: string) {
        return this.http.get<Response<UserDetail>>(environment.apiUrl + 'v1/users/' + userId).pipe(pluck('data'));
    }
}
