<span
    class="ui-label"
    [attr.for]="for"
>
    <span class="item-label">
        <span class="label"
            (click)="onLabelTextClick($event)"
        ><span class="order" *ngIf="order" [attr.order]="order"></span>{{label}}</span>
        <!-- @TODO: tooltip -->
        <span *ngIf="tooltip" class="icon"  [title]="tooltip"><ion-icon name="help-circle-sharp"></ion-icon></span>
    </span>
    <span #content class="item-content" [style.width]="contentSize && 'calc('+contentSize+' + var(--ui-item-gap))'" [style.flexBasis]="contentSize && 'calc('+contentSize+' + var(--ui-item-gap))'">
        <ng-content></ng-content>
    </span>
</span>
