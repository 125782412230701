import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[dropdown]' })
export class OverlayDirective implements OnInit, OnDestroy {
    @Input() dropdown: TemplateRef<any>;
    @Input() config: OverlayConfig;

    private overlayRef: OverlayRef;

    @HostListener('click')
    open() {
        this.attachOverlay();
    }

    constructor(private elementRef: ElementRef, private vcr: ViewContainerRef, private overlay: Overlay) {}

    ngOnInit() {
        const overlayConfig = this.getOverlayConfig();
        this.overlayRef = this.overlay.create(overlayConfig);

        this.overlayRef
            .backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(event => {
                this.detachOverlay();
            });
    }

    ngOnDestroy() {
        this.overlayRef.dispose();
    }

    private attachOverlay(): void {
        if (!this.overlayRef.hasAttached()) {
            const templatePortal = new TemplatePortal(this.dropdown, this.vcr);

            this.overlayRef.attach(templatePortal);
        }
    }

    private detachOverlay(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }

    private getOverlayConfig() {
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                new ConnectionPositionPair(
                    { originX: 'start', originY: 'bottom' },
                    { overlayX: 'start', overlayY: 'top' },
                ),
                new ConnectionPositionPair(
                    { originX: 'start', originY: 'top' },
                    { overlayX: 'start', overlayY: 'bottom' },
                ),
            ]);

        return new OverlayConfig({
            ...this.config,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy,
        });
    }
}
