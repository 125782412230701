import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { AppPath, NavigateService } from 'src/app/services/navigate.service';

@Component({
    selector: 'app-ui-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ClientBackButtonComponent implements OnInit {
    @Input() path: AppPath;
    @Input() url: string;

    constructor(private navigate: NavigateService) {}

    ngOnInit() {}

    onBackButtonClick() {
        if (this.path) {
            this.navigate.to(this.path, this.url);
        } else {
            this.navigate.back();
        }
    }
}
