import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class BackButtonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
