import {
    Component,
    ViewEncapsulation,
    HostBinding,
    ChangeDetectorRef,
    Optional,
    ViewChild,
    ElementRef,
    QueryList,
    Input,
    ViewChildren,
    forwardRef,
    AfterContentChecked,
} from '@angular/core';
import { GenericInputComponent } from '../input/generic-input-component';
import { ItemComponent } from '../item/item.component';
import { ButtonComponent } from '../button/button.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { toBoolean } from '../../helpers/to-boolean';

const textInputAsControlValueAccessorProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ButtonsInputComponent),
    multi: true,
};

@Component({
    selector: 'ui-buttons-input',
    templateUrl: './buttons-input.component.html',
    styleUrls: ['./buttons-input.component.sass'],
    providers: [textInputAsControlValueAccessorProvider],
    encapsulation: ViewEncapsulation.None,
})
export class ButtonsInputComponent extends GenericInputComponent implements AfterContentChecked {
    @HostBinding('class.ui-form-item') formItem = true;

    @ViewChildren(ButtonComponent) protected buttons: QueryList<ButtonComponent>;

    @ViewChild('formInput', { static: true })
    element: ElementRef;

    @HostBinding('style.width')
    @Input()
    size?: string;

    @HostBinding('attr.with-pin')
    @Input()
    withPin: boolean | 'dark' = false;

    @Input()
    values: ButtonOptions[];

    @Input()
    narrow = false;

    // [evenSize]
    //     true: evenSize | evenSize="true" | [evenSize]="true"
    //     false: no attribute | evenSize="false" | [evenSize]="false" | [evenSize]
    @Input()
    public set evenSize(val) {
        this._evenSize = toBoolean(val);
    }
    public get evenSize() {
        return this._evenSize;
    }

    /** When evenSize is true, then all inner buttons have same size. */
    private _evenSize = false;

    constructor(
        changeDetector: ChangeDetectorRef,
        public elementRef: ElementRef, // for reading element from outside (new-task reads offsetWidth)
        @Optional() public parentItem?: ItemComponent,
    ) {
        super(changeDetector, parentItem);
    }

    ngAfterContentChecked() {
        if (this.buttons) {
            this.buttons.forEach(button => {
                if (button.value === this.value) {
                    button.activate();
                } else {
                    button.deactivate();
                }
            });
        }
    }

    setActiveButton(activatedButton: ButtonComponent) {
        this.value = activatedButton.value;
    }

    getValue(options: ButtonOptions) {
        return 'value' in options ? options.value : options.name;
    }

    // gives information of cols/rows count for variation [evenSize]
    @HostBinding('attr.internal--button-count')
    private get _buttonCount() {
        return this._evenSize && this.buttons && this.buttons.length > 1 ? this.buttons.length : undefined;
    }
}

interface ButtonOptions {
    name: string;
    value?: any;
    icon?: string;
    checked?: boolean;
    extraClass?: string;
    disabled?: boolean;
    badge?: {
        value: any;
    };
}
