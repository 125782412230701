import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-dom',
    templateUrl: './dom.component.html',
    styleUrls: ['./dom.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class DomComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
