import { Component, ViewEncapsulation, Renderer2, AfterViewInit, NgZone } from '@angular/core';
import { MessageSenderService } from './services/message-sender.service';
import { MessageReceiverService } from './services/message-receiver.service';
import { ActionTypes } from '../../shared/types/message.types';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TrackingService } from './services/tracking.service';
// import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
    constructor(
        // private platform: Platform,
        // private splashScreen: SplashScreen,
        // private statusBar: StatusBar,
        private messageReceiver: MessageReceiverService,
        private messageSender: MessageSenderService,
        private renderer: Renderer2,
        private zone: NgZone,
        private router: Router,
        private route: ActivatedRoute,
        private track: TrackingService,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        // our code
        this.initTracking();
        this.initEventListeners();
        this.messageSender.post({ action: ActionTypes.getConfig });
        // to speedup first app display (and resizing to the stored UI size)
        document.documentElement.classList.add('no-animations');

        // original code
        // this.platform.ready().then(() => {
        //   this.statusBar.styleDefault();
        //   this.splashScreen.hide();
        // });
    }

    ngAfterViewInit() {
        // let know SDK that app is ready
        this.messageSender.post({ action: ActionTypes.appInitialized });

        setTimeout(() => {
            document.documentElement.classList.remove('no-animations');
        }, 1000);

        // block right click in app when in clients page
        // window.addEventListener('contextmenu', (e: MouseEvent) => {
        //   if (window !== window.parent) {
        //     e.preventDefault();
        //   }
        // });
    }

    // to blur overlay in clients website when mouse is inactive
    // (because when it is blured all time, it slows down animation of UI elements)
    initEventListeners() {
        this.messageReceiver.init(this.renderer);

        let process: any;

        this.zone.runOutsideAngular(() => {
            // window.addEventListener('mousemove', (e: MouseEvent) => handleActivity(e));
            window.addEventListener('mouseover', (e: MouseEvent) => handleActivity(e));
            window.addEventListener('mousedown', (e: MouseEvent) => handleActivity(e));
            window.addEventListener('mousewheel', (e: MouseEvent) => handleScroll(e));
            window.addEventListener('DOMMouseScroll', (e: MouseEvent) => handleScroll(e));
            window.addEventListener('mousemove', (e: MouseEvent) => handleMouseMove(e));

            const self = this;

            function handleActivity(e: MouseEvent) {
                if (!process) {
                    self.messageSender.post({ action: ActionTypes.appWakeUp });
                }
                clearTimeout(process);
                process = setTimeout(() => {
                    self.messageSender.post({ action: ActionTypes.appSleep });
                    process = null;
                }, 3000);
            }

            let tout: any;
            // show scrollbar when scrolling (see page-content.component.sass)
            function handleScroll(e: MouseEvent) {
                handleActivity(e);
                clearTimeout(tout);
                document.documentElement.classList.add('scrolling');
                tout = setTimeout(() => {
                    document.documentElement.classList.remove('scrolling');
                }, 800);
            }

            let tout2: any;
            // when moving in large elements, we need handle also
            // mousemove event becouse mouseover event is not triggering
            function handleMouseMove(e: MouseEvent) {
                if (!tout2) {
                    handleActivity(e);
                    tout2 = setTimeout(() => {
                        tout2 = undefined;
                    }, 1000);
                }
            }
        });

        // Esc key closes app
        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                this.messageSender.post({ action: ActionTypes.appClose });
            }
        });

        // prevent animate resizing of iframe for slower computers
        let lastTimeResize = Date.now();
        const LOW_FPS = 36;
        let currentResizeFps = LOW_FPS + 4;
        const preserveKoef = 24;
        let stoppedResizeAnimation = false;
        window.addEventListener('resize', (e: Event) => {
            if (currentResizeFps < LOW_FPS) return;
            const now = Date.now();
            const time = now - lastTimeResize;
            // ignore long delays
            if (time < 2000) {
                const fps = 1000 / time;
                currentResizeFps = (currentResizeFps * preserveKoef + fps) / (preserveKoef + 1);
                if (currentResizeFps < LOW_FPS) {
                    this.messageSender.post({ action: ActionTypes.stopAnimatedResizing });
                    stoppedResizeAnimation = true;
                }
            }
            lastTimeResize = now;
        });
    }

    initTracking() {
        this.track.appOpen();
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.leafRoute()),
                filter((route: ActivatedRoute) => route.outlet === 'primary'),
            )
            .subscribe(route => {
                this.track.pageView({ pageName: route.component['trackingPageName'] || route.component['name'] });
            });
    }

    leafRoute() {
        let route = this.route;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
}
