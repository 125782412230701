import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ResizeService } from './resize.service';
import { MessageSenderService } from './message-sender.service';
import { Router } from '@angular/router';
import { ActionTypes } from '../../../shared/types/message.types';

@Injectable({
    providedIn: 'root',
})
export class NavigateService {
    constructor(
        private navCtrl: NavController,
        private resize: ResizeService,
        private router: Router,
        private messageSender: MessageSenderService,
    ) {}

    private history: { path: AppPath; url: string }[] = [];

    root() {
        const path = '/home';
        this.resize.setByPath(path);
        this.navCtrl.navigateBack(path as AppPath, { replaceUrl: true });
        this.history = [];
    }

    to(path: AppPath, url?: string, ignoreHistory = false) {
        if (path === '/tasks' || path === '/tasks/detail' || path === '/tasks/new') {
            this.messageSender.post({ action: ActionTypes.refreshGlobalCss });
        }

        this.resize.setByPath(path);
        this.navCtrl.navigateForward(url ? url : path, { replaceUrl: true });

        if (!ignoreHistory) {
            this.history.push({ path, url });
        }
    }

    back() {
        this.history.pop();

        if (this.history.length > 0) {
            const { path, url } = this.history[this.history.length - 1];
            this.resize.setByPath(path);
            this.navCtrl.navigateBack(url ? url : path, { replaceUrl: true });
        } else {
            this.root();
        }
    }
}

export type AppPath =
    | '/home'
    | '/tasks'
    | '/tasks/new'
    | '/tasks/new#minimized'
    | `/tasks/detail`
    | `/tasks/preview`
    | '/issues'
    | '/report-issue'
    | '/report-issue#from-task'
    | '/report-issue#minimized'
    | '/designs'
    | '/showcase'
    | '/settings'
    | '/init'
    | '/styles'
    | '/modifications'
    | '/modifications/detail'
    | '/login'
    | '/task-modifications'
    | '/modification-diff';
