<ion-icon 
	class="icon"
	name="{{ getIcon() }}"
></ion-icon>
<span class="text">{{ alert.message }}</span>
<ion-icon
class="close-button"
name="close-circle-outline"
(click)="onCloseBtnClick()"
></ion-icon>
<span
	class="timeout-progress"
	[style.transition-duration]="alert.timeout+'s'"
></span>
