import { Component, OnInit, ViewEncapsulation, HostBinding } from '@angular/core';

@Component({
    selector: 'ui-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ButtonGroupComponent implements OnInit {
    @HostBinding('class.ui-form-item') formItem = true;

    constructor() {}

    ngOnInit() {}
}
