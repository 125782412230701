import { AlertType } from 'src/app/ui/components/alerts/alerts.service';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Alert } from '../alerts.service';

const DEFAULT_TIMEOUT = 10; // sec

@Component({
    selector: 'ui-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {
    @Input() alert: Alert;

    @Output() close = new EventEmitter<void>();

    @HostBinding('attr.type')
    get type() {
        return this.alert.type;
    }

    constructor(private changeDetector: ChangeDetectorRef) {}

    @HostBinding('class.countdown')
    countdown = false;

    closeProcess: ReturnType<typeof setTimeout>;

    ngOnInit() {
        if (this.alert.timeout === undefined) {
            this.alert.timeout = DEFAULT_TIMEOUT;
        }
        if (this.alert.timeout !== 0) {
            this.closeProcess = setTimeout(() => {
                this.close.emit();
            }, this.alert.timeout * 1000);

            setTimeout(() => {
                this.countdown = true;
                this.changeDetector.markForCheck();
            }, 10);
        }
    }

    onCloseBtnClick() {
        clearTimeout(this.closeProcess);
        this.close.emit();
    }

    getIcon() {
        switch (this.alert.type) {
            case AlertType.success:
                return 'checkmark-circle-sharp';

            case AlertType.error:
                return 'alert-circle-sharp';

            case AlertType.warning:
                return 'warning-sharp';

            case AlertType.info:
                return 'information-circle-sharp';

            default:
                return '';
        }
    }
}
