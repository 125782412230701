import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { enterAnimation } from './animations/nav-animation';
import { NavigateService } from './services/navigate.service';
import { MessageSenderService } from './services/message-sender.service';
import { ProjectService } from './services/project.service';
import { ResizeService } from './services/resize.service';
import { DemoService } from './services/demo.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageReceiverService } from './services/message-receiver.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AsyncPipe, DatePipe } from '@angular/common';
import { PreviousRouteService } from './services/previous-route.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { UiClientModule } from './ui/ui-client.module';
import { environment } from '../environments/environment';
import { API_BASE_URL } from '../../messaging';
import { AuthInterceptor } from './auth.interceptor';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserAnimationsModule, // BrowserModule,
        IonicModule.forRoot({
            rippleEffect: false,
            mode: 'md',
            navAnimation: enterAnimation,
        }),
        AppRoutingModule,
        HttpClientModule,
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        OverlayModule,
        UiClientModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        NavigateService,
        MessageSenderService,
        MessageReceiverService,
        ProjectService,
        ResizeService,
        DemoService,
        AsyncPipe,
        DatePipe,
        // We want PreviousRouteService to be initialized right away
        { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [PreviousRouteService], multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: API_BASE_URL, useValue: `${environment.apiUrl}` },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
