import { Urgency } from "../types/task.types";

export const urgencyLabels = {
    [Urgency.low]: 'very low',
    [Urgency.normal]: 'normal',
    [Urgency.urgent]: 'urgent',
    [Urgency.asap]: 'asap',
};

// Urgency long texts are in new-task.page.html

export const urgencyDeliveryTimeLabels = {
    [Urgency.low]: '10 workdays',
    [Urgency.normal]: '5 workdays',
    [Urgency.urgent]: '3 workdays',
    [Urgency.asap]: '24 hours',
};
