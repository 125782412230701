import {
    Component,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    HostListener,
    Output,
    EventEmitter,
    NgZone,
} from '@angular/core';

@Component({
    selector: 'ui-page-content',
    templateUrl: './page-content.component.html',
    styleUrls: ['./page-content.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class PageContentComponent implements OnInit {
    // timeout handler
    repairScrollTimeout: any;

    @Output()
    onScroll: EventEmitter<ScrollInfo> = new EventEmitter();

    constructor(public elementRef: ElementRef, private zone: NgZone) {}

    ngOnInit() {
        this.repairScrollPosition();
    }

    scrollBy(x: number, y: number) {
        this.elementRef.nativeElement.scrollBy(x, y);
    }

    @HostListener('scroll', ['$event'])
    _onScrollHandler() {
        this.repairScrollPosition();
        const el = this.elementRef.nativeElement;
        this.onScroll.emit({
            left: el.scrollLeft,
            top: el.scrollTop,
            scrollWidth: el.scrollWidth,
            scrollHeight: el.scrollHeight,
            width: el.offsetWidth,
            height: el.offsetHeight,
        });
    }

    // preventing scrolling of client site when scrolling in our app
    // when scroll reach the very top or bottom, then it will start scrolling clients page
    // solution is scroll 1px from very top/bottom of the app scroll content
    repairScrollPosition() {
        // limit calling to 1 per 100ms
        clearTimeout(this.repairScrollTimeout);
        this.repairScrollTimeout = setTimeout(() => this._repairScrollPosition(), 50);
    }

    _repairScrollPosition() {
        if (!this.elementRef || !this.elementRef.nativeElement) {
            return;
        }
        const el = this.elementRef.nativeElement;
        const currentPos = Math.round(el.scrollTop);

        // if scrolled max bottom
        if (el.scrollHeight - el.offsetHeight === currentPos && currentPos !== 0 && currentPos !== 1) {
            el.scrollTop = currentPos - 1;
        }
        // if scrolled max top
        else if (el.scrollTop === 0 && el.scrollHeight !== el.offsetHeight) {
            el.scrollTop = 1;
        }
    }

    // duration in ms
    scrollTo(x: number, y: number, duration?: number) {
        if (!duration) {
            this.elementRef.nativeElement.scrollTo(x, y);
        } else {
            this.animateScrollTo(x, y, duration);
        }
    }

    animateScrollTo(x: number, y: number, duration: number) {
        const startTime = Date.now();
        const endTime = startTime + duration;
        const el = this.elementRef.nativeElement;
        const sx = el.scrollLeft;
        const sy = el.scrollTop;
        const pi = Math.PI;
        this.zone.runOutsideAngular(() => {
          requestAnimationFrame(scroll);

          function scroll() {
            const now = Date.now();

            if (now < endTime) {
              let t = (now - startTime) / duration;
              if (t > 1) {
                t = 1;
              }
              const phase = Math.sin(-pi / 2 + t * pi) / 2 + 0.5;
              const vx = x - sx;
              const vy = y - sy;
              el.scrollTo(sx + vx * phase, sy + vy * phase);

              requestAnimationFrame(scroll);
            } else {
              el.scrollTo(x, y);
            }
          }
        });
    }
}

export type ScrollInfo = {
    left: number;
    top: number;
    scrollWidth: number;
    scrollHeight: number;
    width: number;
    height: number;
};
