<ui-button
    class="{{class}}"
    [attr.align]="align"
    [icon]="icon"
    [active]="active"
    [value]="value"
    [priority]="priority"
    [focused]="focused"
    [required]="required"
    [disabled]="disabled"
    [readonly]="readonly"
    [id]="id"
    [name]="name"
    [placeholder]="placeholder"
    [partialId]="partialId"
    [showcaseMode]="showcaseMode"
    [autocomplete]="autocomplete"
    [size]="size"
    [editableLabel]="editableLabel"
    (onClick)="onBtnClick($event)"
    (onActivate)="onActivate.emit($event)"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
><ng-content></ng-content></ui-button>
