<ui-button
    *ngFor="let button of values"
    class="small centered {{ button.extraClass }}"
    [class.internal--with-pin]="withPin"
    [class.internal--with-pin-dark]="withPin==='dark'"
    [class.narrow]="narrow"
    [value]="getValue(button)"
    [icon]="button.icon"
    [checked]="button.checked"
    [disabled]="button.disabled"
    (onClick)="setActiveButton($event)"
>{{ button.name }} <span *ngIf="button.badge">({{ button.badge.value || 0 }})</span></ui-button>