import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AppPath, NavigateService } from 'src/app/services/navigate.service';
import { ButtonComponent } from '../components/button/button.component';
import { toBoolean } from '../helpers/to-boolean';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ClientButtonComponent implements OnInit {
    @Input() icon: string;
    @Input() active: boolean;
    @Input() navigateRoot: string;
    @Input() navigateForward: string;
    @Input() value: any;
    @Input() priority: 'normal' | 'primary' | 'secondary' | 'positive' | 'negative' = 'normal';
    @Input() focused = false;
    @Input() required: any;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() id?: string;
    @Input() name?: string;
    @Input() placeholder?: string;
    @Input() partialId?: string;
    @Input() showcaseMode = false;
    @Input() autocomplete: string;
    @Input() size?: string;
    @Input() editableLabel = false;

    // additional inputs
    @Input() class: string;
    @Input() align: string;

    @Output() onClick: EventEmitter<ButtonComponent> = new EventEmitter();
    @Output() onActivate: EventEmitter<ButtonComponent> = new EventEmitter();
    @Output() blur: EventEmitter<FocusEvent> = new EventEmitter();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();

    @ViewChild(ButtonComponent) private btn: ButtonComponent;

    constructor(private navigate: NavigateService) {}

    ngOnInit() {}

    onBtnClick(ev: ButtonComponent) {
        this.onClick.emit(ev);

        if (!this.btn.disabled) {
            setTimeout(() => {
                if (this.navigateRoot) {
                    this.navigate.to(this.navigateRoot as AppPath);
                } else if (this.navigateForward) {
                    this.navigate.to(this.navigateForward as AppPath);
                }
            }, 0 * 100); // to delay button animation (on mobile device - because performance)
        }
    }
}
