import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KlikDirective } from './klik/klik.directive';
import { OverlayDirective } from './overlay.directive';

const uiComponents = [KlikDirective, OverlayDirective];

@NgModule({
    declarations: [...uiComponents],
    exports: [...uiComponents],
    imports: [CommonModule],
})
export class DirectivesModule {}
