import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
/**
 * When supportModeOnly == true then cannot active route
 */
export class SupportModeGuard implements CanActivate {
    constructor(private configService: ConfigService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.configService.getConfig$().pipe(
            take(1),
            map(config => !config?.supportModeOnly),
        );
    }
}
