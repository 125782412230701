import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
    name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
    constructor(private dateService: DateService) {}

    transform(dateTime: string) {
        const second = 1000;
        const minute = 60 * second;
        const hour = 60 * minute;
        const day = 24 * hour;
        const threeDays = 3 * day;

        const lastDate = new Date(dateTime).getTime();
        const now = new Date().getTime();

        const diff = now - lastDate;

        if (diff > threeDays + day) {
            return this.dateService.formatDate(lastDate, 'mediumDate');
        } else if (diff > day) {
            return `${Math.floor(diff / day)} day(s) ago`;
        } else if (diff > hour) {
            return `${Math.floor(diff / hour)} hour(s) ago`;
        } else if (diff > minute) {
            return `${Math.floor(diff / minute)} minute(s) ago`;
        } else {
            return 'right now';
        }
    }
}
