import { Injectable } from '@angular/core';
import { NavigateService } from './services/navigate.service';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { catchError, delay, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ErrorsService } from './services/errors.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private errorsService: ErrorsService,
        private navigateService: NavigateService,
    ) {}

    addAuthHeader(request: HttpRequest<any>) {
        const accessToken = this.auth.getAccessToken();
        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
        });
    }

    handleResponseError(error: any, request: HttpRequest<any>, next: HttpHandler) {
        if (error.status === 401) {
            const isAlreadyRefreshTokenCall = error.url && error.url.indexOf('/auth/refresh') > -1;
            // try to get new access token with refresh token
            if (!isAlreadyRefreshTokenCall) {
                return this.auth.waitRefreshToken().pipe(
                    catchError(_error => {
                        // we failed to refresh access token from server using refresh token
                        return this.logoutAndRedirectToLogin(error);
                    }),
                    switchMap(() => {
                        request = this.addAuthHeader(request);
                        return next.handle(request);
                    }),
                );
            } else {
                // refresh token call failed with 401 -> redirect to login page
                return this.logoutAndRedirectToLogin(error);
            }
        } else if (error.status === 0) {
            this.errorsService.handle0(error.error);
        } else if (error.status === 400) {
            this.errorsService.handle400(error.error);
        } else if (error.status === 403) {
            this.errorsService.handle403(error.error);
        } else {
            this.errorsService.handleOther(error.error);
        }

        return throwError(error);
    }

    private logoutAndRedirectToLogin(error) {
        // only sensible action is to logout
        this.auth.logOutLocal();
        this.navigateService.to('/login');
        return throwError(error);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (this.auth.getAccessToken() !== '') {
            request = this.addAuthHeader(request);
            return next.handle(request).pipe(
                retryWhen(errors => {
                    return errors.pipe(
                        tap(error => {
                            if (error.statusText !== 'Unknown Error') {
                                throw error;
                            }
                        }),
                        take(3),
                        delay(1000),
                    );
                }),
                catchError(error => {
                    return this.handleResponseError(error, request, next);
                }),
            );
        }

        this.auth.waitRefreshToken().pipe(
            catchError(error => {
                return error;
            }),
            switchMap(() =>
                next.handle(request).pipe(
                    retryWhen(errors => {
                        return errors.pipe(
                            tap(error => {
                                if (error.statusText !== 'Unknown Error') {
                                    throw error;
                                }
                            }),
                            take(3),
                            delay(1000),
                        );
                    }),
                    catchError(error => {
                        return this.handleResponseError(error, request, next);
                    }),
                ),
            ),
        );

        return next.handle(request).pipe(
            retryWhen(errors => {
                return errors.pipe(
                    tap(error => {
                        if (error.statusText !== 'Unknown Error') {
                            throw error;
                        }
                    }),
                    take(3),
                    delay(1000),
                );
            }),
            catchError(error => {
                return this.handleResponseError(error, request, next);
            }),
        );
    }
}
