import { Component, OnInit, ViewEncapsulation, HostBinding, Input, Output, EventEmitter } from '@angular/core';
import { toBoolean } from '../../helpers/to-boolean';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'ui-collapsible',
    templateUrl: './collapsible.component.html',
    styleUrls: ['./collapsible.component.sass'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('collapse', [
            state('false', style({ height: '*' })),
            transition('false => true', [animate('.25s ease-in-out', style({ height: 0 }))]),
            state('true', style({ height: 0 })),
            transition('true => false', [animate('.25s ease-in-out', style({ height: '*' }))]),
        ]),
    ],
})
export class CollapsibleComponent implements OnInit {
    @HostBinding('class.collapsed')
    @Input()
    collapsed = false;

    @Output()
    collapsedChange = new EventEmitter<boolean>();

    // disabled
    //     true: disabled | disabled="true" | disabled="required" | disabled="any value" | [disabled]="true"
    //     false: no attribute | disabled="false" | [disabled]="false" | [disabled]
    @Input()
    public set disabled(val: any) {
        this._disabled = toBoolean(val);
    }
    public get disabled(): any {
        return this._disabled;
    }
    @HostBinding('class.disabled') private _disabled = false;

    constructor() {}

    ngOnInit() {}

    toggleCollapsed(): void {
        if (!this.disabled) {
            this.collapsed = !this.collapsed;
            this.collapsedChange.emit(this.collapsed);
        }
    }
}
