import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { ConfigService } from './config.service';
import { UsersService } from './users.service';

@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    private _version: string = '?';
    public get version(): string {
        return this._version;
    }

    constructor(private config: ConfigService, private usersService: UsersService) {}

    getProjectId() {
        return this.config.getConfig()?.projectId;
    }

    identifyUserId(userId: string) {
        this.usersService.getUserDetail(userId).subscribe(userDetail => {
            mixpanel.identify(userDetail.email);
        });
    }

    appOpen() {
        mixpanel.track('App Open', {
            'Project Id': this.getProjectId(),
            'Open After Init': this.config.getConfig()?.openAfterInit,
            'Client Version': this.version,
        });
    }

    login(props: { email: string }) {
        mixpanel.identify(props.email);
        mixpanel.track('Login', {
            'Project Id': this.getProjectId(),
            'Client Version': this.version,
        });
    }

    logout() {
        mixpanel.track('Logout', { 'Client Version': this.version });
        mixpanel.reset();
    }

    pageView(props: PageViewProps) {
        const tprops = new TrackingProps();
        tprops.append('Project Id', this.getProjectId());
        tprops.append('Client Version', this.version);
        tprops.append('Page Name', props.pageName);

        tprops.append('Task Id', props.taskId);
        tprops.append('Task Name', props.taskName);
        tprops.append('Task State', props.taskState);
        tprops.append('Task Urgency', props.taskUrgency);
        tprops.append('Task Time to Deadline', props.taskTimeToDeadline);
        tprops.append('Task Type', props.taskType);

        tprops.append('Modification Id', props.modificationId);

        mixpanel.track('Page View', tprops.get());
    }
}

class TrackingProps {
    private _data = {};

    append(key: string, value?: string) {
        if (value) {
            this._data[key] = value;
        }
    }

    get() {
        return this._data;
    }
}

type AllOrNothing<T> = T | Partial<Record<keyof T, undefined>>;

type PageViewProps = {
    pageName: string;
} & AllOrNothing<{
    taskId: string;
    taskName: string;
    taskState: string;
    taskUrgency: string;
    taskTimeToDeadline: string;
    taskType: string;
}> &
    AllOrNothing<{
        modificationId?: string;
    }>;
