<ui-page-header>
	<ng-content select="[header]"></ng-content>
	<ui-close-button
		icons
		*ngIf="showCloseBtn"
		(click)="close.emit()"
	></ui-close-button>
</ui-page-header>
<ui-page-content>
	<ng-content select="[body]"></ng-content>
</ui-page-content>
<ui-page-footer class="narrow">
	<ng-content select="[footer]"></ng-content>
</ui-page-footer>
