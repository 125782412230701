import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SDKConfigType } from '../../../shared/types/sdk';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private configSubject = new BehaviorSubject<SDKConfigType>(null);
    private config$ = this.configSubject.asObservable();

    constructor() {}

    updateConfig(config: SDKConfigType) {
        this.configSubject.next(config);
    }

    getConfig() {
        return this.configSubject.getValue();
    }

    getConfig$() {
        return this.config$;
    }
}
