import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    HostBinding,
    forwardRef,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
    Optional,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { INLINE_EDIT_TARGET_TOKEN } from './inline-edit-target';
import { GENERIC_INPUT_TOKEN, GenericInputComponent } from './generic-input-component';
import { ItemComponent } from '../item/item.component';

const textInputAsControlValueAccessorProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true,
};

const textInputAsComponentProvider = {
    provide: GENERIC_INPUT_TOKEN,
    useExisting: forwardRef(() => InputComponent),
};

const textInputAsInlineEditTargetProvider = {
    provide: INLINE_EDIT_TARGET_TOKEN,
    useExisting: forwardRef(() => InputComponent),
};

@Component({
    selector: 'ui-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.sass'],
    providers: [
        textInputAsControlValueAccessorProvider,
        textInputAsComponentProvider,
        textInputAsInlineEditTargetProvider,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class InputComponent extends GenericInputComponent implements OnInit {
    @ViewChild('formInput', { static: false })
    element: ElementRef;

    @HostBinding('attr.role') role = 'textbox';

    @HostBinding('style.width')
    @Input()
    size?: string;

    @HostBinding('class.editable')
    @Input()
    editable = false;

    @Input()
    rows = 3;

    otherValue = 'testing';

    @HostBinding('attr.type')
    @Input()
    type: 'text' | 'textarea' | 'password' | 'email' | "number" = 'text';

    @Input() maxlength: number;

    // only when editable is turned on
    isEditing = false;

    // @TODO dont know why [(ngModel)]="value" in the template does not work ?!?!?!?!?\
    // so I use  (value)="setValue()" and [value]="value"
    setValue(val: any) {
        this.value = val;
    }

    constructor(changeDetector: ChangeDetectorRef, @Optional() public parentItem?: ItemComponent) {
        super(changeDetector, parentItem);
    }

    ngOnInit() {}

    onBlur(event: FocusEvent) {
        if (this.editable) {
            this.isEditing = false;
        }

        this.onInnerInputBlur(event);
    }

    onEdit() {
        this.isEditing = true;

        setTimeout(() => {
            this.focus();
        }, 0);
    }
}
