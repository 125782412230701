import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { urgencyDeliveryTimeLabels, urgencyLabels } from '../../helpers/urgency';
import { NavigateService } from '../../services/navigate.service';

@Component({
    selector: 'app-ui-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ClientGridComponent<T> implements OnInit {
    @Input()
    format: GridFormatType<T>;

    @Input()
    data: GridItemType<T>[];

    @Input()
    highlightId: string | null;

    @Input() initSort: Sort<T>;

    @Output() sort = new EventEmitter<Sort<T>>();

    sortedBy: Sort<T>;
    urgencyLabels = urgencyLabels;
    urgencyDeliveryTimeLabels = urgencyDeliveryTimeLabels;

    constructor(private navigateService: NavigateService) {}

    ngOnInit() {
        this.sortedBy = this.initSort;
    }

    navigateToDetail(id: string) {
        this.navigateService.to('/tasks/detail', `/tasks/${id}`);
    }

    onTableHeaderClick(key: GridColumnType<T>['key']) {
        if (this.sortedBy?.key === key) {
            this.sortedBy.order = this.sortedBy.order === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortedBy = {
                key,
                order: 'asc',
            };
        }

        this.sort.emit(this.sortedBy);
    }

    trackBy(index: number, item: GridItemType<T>) {
        return item.id;
    }

    isSortedAsc(key: GridColumnType<T>['key']) {
        return this.sortedBy && this.sortedBy.key === key && this.sortedBy.order === 'asc';
    }

    isSortedDesc(key: GridColumnType<T>['key']) {
        return this.sortedBy && this.sortedBy.key === key && this.sortedBy.order === 'desc';
    }

    getSortIcon(key: GridColumnType<T>['key']) {
        return this.isSortedAsc(key) ? 'caret-up-outline' : this.isSortedDesc(key) ? 'caret-down-outline' : '';
    }
}

export type GridFormatType<T> = GridColumnType<T>[];

export type GridColumnType<T> = {
    key: string;
    name?: string;
    align?: 'left' | 'center' | 'right';
    valign?: 'top' | 'middle' | 'bottom';
    wrap?: boolean;
    orderable?: boolean;
    size?: string; // CSS value
    extraClass?: string;
    icon?: string;
    disabled?: boolean;
    formatter?: (value: T) => string;
    templateRef?: TemplateRef<{
        $implicit: T;
        index: number;
    }>;
};

export type GridItemType<T> = {
    id: string;
    extraClass?: ExtraClasses;
    hasParent: boolean;
} & T;

export enum ExtraClasses {
    'deemphasize' = 'deemphasize',
}

export interface Sort<T> {
    key: GridColumnType<T>['key'];
    order: 'asc' | 'desc';
}
