import { EnvComponent } from './env/env.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PageContentComponent } from './components/page-content/page-content.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { PaddingComponent } from './components/padding/padding.component';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { LayoutComponent } from './components/layout/layout.component';
import { BoxComponent } from './components/box/box.component';
import { DomComponent } from './components/dom/dom.component';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { TextComponent } from './components/text/text.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BadgeComponent } from './components/badge/badge.component';
import { RouterModule } from '@angular/router';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownItemComponent } from './components/dropdown-item/dropdown-item.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { StateIconComponent } from './components/state-icon/state-icon.component';
import { IonicModule, IonIcon } from '@ionic/angular';
import { ItemComponent } from './components/item/item.component';
import { InputComponent } from './components/input/input.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonsInputComponent } from './components/buttons-input/buttons-input.component';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { StatusComponent } from './components/status/status.component';
import { LogoComponent } from './components/logo/logo.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ConfirmWindowComponent } from './components/confirm-window/confirm-window.component';
import { SelectComponent } from './components/select/select.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertComponent } from './components/alerts/alert/alert.component';
import { ModalComponent } from './components/modal/modal.component';
import { DateService } from './services/date.service';
import { OverlayDirective } from './directives/overlay.directive';
import { DropdownOverlayComponent } from './components/dropdown-overlay/dropdown-overlay.component';

const uiComponents = [
    PageContentComponent,
    PageFooterComponent,
    PaddingComponent,
    BoxComponent,
    BreadcrumbComponent,
    ButtonGroupComponent,
    LayoutComponent,
    DomComponent,
    CollapsibleComponent,
    TextComponent,
    LoaderComponent,
    BadgeComponent,
    DropdownComponent,
    DropdownItemComponent,
    StateIconComponent,
    ItemComponent,
    InputComponent,
    ToggleComponent,
    ButtonComponent,
    ButtonsInputComponent,
    CloseButtonComponent,
    IconButtonComponent,
    BackButtonComponent,
    StatusComponent,
    LogoComponent,
    PageHeaderComponent,
    ConfirmWindowComponent,
    SelectComponent,
    AlertComponent,
    AlertsComponent,
    ModalComponent,
    OverlayDirective,
    DropdownOverlayComponent,
    EnvComponent,
];

@NgModule({
    declarations: [...uiComponents],
    exports: [...uiComponents],
    imports: [CommonModule, FormsModule, RouterModule, IonicModule],
    providers: [DatePipe, DateService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiModule {}

// ionic generate component ui/COMPONENT_NAME --prefix=ui
