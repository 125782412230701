import { pluck, shareReplay, switchMap, map, catchError } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { isCurrentUserMessage, MessageService } from './message.service';
import { EntityType, Message, MessageFilter, MessageReadStatus, NewMessage } from './messaging.types';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserRoles } from '../shared/types/user.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageFormComponent } from './message-form/message-form.component';

@UntilDestroy()
@Component({
  selector: 'scp-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('messageForm') messageForm!: MessageFormComponent;
  @Input() type: Message['type'];
  @Input() projectId: string;
  @Input() entityType: EntityType;
  @Input() entityId: Message['entity']['id'];
  @Input() currentUser: Message['author'];
  @Input() authorSubRole: Message['authorSubrole'];
  @Input() theme: 'light' | 'dark' = 'light';

  @Output() unreadMessages = new EventEmitter<Message[]>();

  @HostBinding('class.dark') get darkTheme() {
    return this.theme === 'dark';
  }

  messages$: Observable<Message[]>;
  refreshSubject = new Subject();

  newMessage: NewMessage = null;

  private lastUnreadMessageId: string;
  private isMessageSending = false;

  private get storageKey(): string {
    return `${this.type}-${this.currentUser.id}-${this.entityId}`;
  }

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.configureListener();
    this.newMessage = this.createNewMessage();
    const savedMessage = localStorage.getItem(this.storageKey);
    if (savedMessage) {
      this.newMessage.text = savedMessage;
    }
    // first tick after 300ms next ticks every 30s
    timer(300, 30000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.refreshSubject.next();
      });
  }

  ngOnDestroy(): void {
    if (this.messageForm.text) {
      localStorage.setItem(this.storageKey, this.messageForm.text);
    } else {
      localStorage.removeItem(this.storageKey);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityId) {
      this.refreshSubject.next();
    }
  }

  getReadStatus(): MessageReadStatus {
    if (this.authorSubRole === 'customer') {
      return 'hidden';
    }

    if (this.authorSubRole === 'styler') {
      return 'visible';
    }

    if (this.authorSubRole === 'task manager') {
      if (this.type === 'internal') {
        return 'visible';
      } else {
        return 'markable';
      }
    }
  }

  createNewMessage(): NewMessage {
    return {
      text: null,
      replyTo: undefined,
      type: this.type,
      visibility: 'private',
      authorSubrole: this.authorSubRole,
    };
  }

  markMessagesAsRead(messages: Message[]) {
    const mode =
      this.type === 'external' && this.authorSubRole !== 'task manager' ? UserRoles.customer : UserRoles.styler;

    this.messageService
      .markMessagesAsRead(messages, this.projectId, this.entityType, this.entityId, mode)
      .subscribe(() => {});
  }

  sendMessage(msg: NewMessage) {
    if (this.isMessageSending) {
        return;
    }
    this.isMessageSending = true;
    const mode =
      this.type === 'external' && this.authorSubRole !== 'task manager' ? UserRoles.customer : UserRoles.styler;

    this.messageService.createMessage(msg, this.projectId, this.entityType, this.entityId, mode).pipe(
        tap(() => {
            this.newMessage = this.createNewMessage();
            this.refreshSubject.next();
            this.isMessageSending = false;
        }),
        catchError(e => {
            this.isMessageSending = false;
            return throwError(e);
        })
    ).subscribe();
    localStorage.removeItem(this.storageKey);
  }

  private configureListener(): void {
    const mode =
      this.type === 'external' && this.authorSubRole !== 'task manager' ? UserRoles.customer : UserRoles.styler;

    const filter: MessageFilter = this.authorSubRole === 'task manager' ? { type: this.type } : null;
    this.messages$ = this.refreshSubject.asObservable().pipe(
      untilDestroyed(this),
      switchMap(() =>
        this.messageService.fetch(
          this.projectId,
          this.entityType,
          this.entityId,
          filter, // filter
          null, // pagination
          mode,
        ),
      ),
      pluck('data'),
      map(messages => messages?.reverse()),
      shareReplay(1),
    );

    this.messages$.subscribe(messages => {
      const newMessages = messages.filter(
        message =>
          !isCurrentUserMessage(message, this.type, this.currentUser) &&
          !message.isReadByOtherPartyDatetime &&
          message.authorSubrole !== this.authorSubRole,
      );

      if (newMessages.length > 0 && newMessages[newMessages.length - 1].id !== this.lastUnreadMessageId) {
        this.lastUnreadMessageId = newMessages[newMessages.length - 1].id;
        this.unreadMessages.emit(newMessages);
      } else {
        this.unreadMessages.emit([]);
      }
    });
  }
}
