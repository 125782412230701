import { Injectable } from '@angular/core';
import { AlertsService, AlertType } from '../ui/components/alerts/alerts.service';
import { MessageReceiverService } from './message-receiver.service';
import { ResponseError } from '../../../shared/types/http-response.types';
import { ActionTypes, Message } from '../../../shared/types/message.types';

@Injectable({
    providedIn: 'root',
})
export class ErrorsService {
    constructor(private alertsService: AlertsService, private messageReceiver: MessageReceiverService) {
        this.messageReceiver.addEventListener((message: Message) => {
            if (message.action === ActionTypes.SDKError) {
                console.log(message.payload);
                switch (message.payload.status) {
                    // Unknown error, so far CORS Errors
                    case 0:
                        this.handle0(message.payload);
                        break;
                    case 400:
                        this.handle400(message.payload);
                        break;
                    case 401:
                        this.handle401(message.payload);
                        break;
                    case 403:
                        this.handle403(message.payload);
                        break;
                    default:
                        console.log('Unhandled error: ', message.payload);
                }
            }
        });
    }

    // status is equal to 0 when Unknown Error, eg. CORS error
    handle0(error: ResponseError) {
        this.alertsService.clearAlerts();
        this.alertsService.addAlert(`Server error! Please try later or contact support.`, AlertType.warning);
    }

    handle401(error: ResponseError) {
        this.alertsService.clearAlerts();
        this.alertsService.addAlert(`Your access and refresh token have expired. Please log in again.`, AlertType.info);
    }

    handle400(error: ResponseError) {
        // TODO set correct context for sentry from the error message;
        this.alertsService.clearAlerts();
        this.alertsService.addAlert(
            `There's been error on our side. We're going to take a look at it asap. Please try again later.`,
            AlertType.error,
            0,
        );
    }

    handle403(error: ResponseError) {
        // TODO set correct context for sentry from the error message;
        this.alertsService.clearAlerts();
        this.alertsService.addAlert(`You don't have necessary user rights to perform this action.`, AlertType.error, 0);
    }

    handleOther(error: ResponseError) {
        // TODO set correct context for sentry from the error message;
        this.alertsService.clearAlerts();
        this.alertsService.addAlert(`Error! Please try later or contact support.`, AlertType.error, 0);
    }
}
