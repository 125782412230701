import { Component, OnInit, ViewEncapsulation, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'ui-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class StatusComponent implements OnInit {
    @HostBinding('attr.type')
    @Input()
    type: 'info' | 'success' | 'error' | 'warning' | 'help' | 'note' = 'info';

    constructor() {}

    ngOnInit() {}

    getIcon() {
        if (this.type === 'success') {
            return 'checkmark-circle-sharp';
        }
        if (this.type === 'warning') {
            return 'alert-circle-sharp';
        }
        if (this.type === 'help') {
            return 'help-circle-sharp';
        }
        // info
        return 'information-circle-sharp';
    }
}

// gray
// <ion-icon name="information-circle-sharp"></ion-icon>
// <ion-icon name="ellipsis-horizontal-circle-sharp"></ion-icon>
// <ion-icon name="remove-circle-sharp"></ion-icon>

// green
// <ion-icon name="checkmark-circle-sharp"></ion-icon>

// red
// <ion-icon name="alert-circle-sharp"></ion-icon>
// <ion-icon name="close-circle-sharp"></ion-icon>
// <ion-icon name="remove-circle-sharp"></ion-icon>

// yellow
// <ion-icon name="help-circle-sharp"></ion-icon>
