import { PageContext, TaskElement } from './task.types';
import { WindowPosition, AppMode as App_Mode, UISize } from './app.types';
import { ResponseError } from './http-response.types';
import { StyleTarget } from './new-task.types';
import { ReplicaMessage } from './messages/replica-message.types';
import { PlatformMessage } from './messages/platform-message.types';
import { DOMagicMessage } from './messages/domagic-message.types';
import { SDKConfigType } from './sdk';

export type Message =
    | ClientMessage
    | SDKMessage
    | ReplicaMessage.ReplicaMessage
    | PlatformMessage.PlatformMessage
    | ExtensionMessage
    | DOMagicMessage.DOMagicMessage;

export type ExtensionMessage = {
    origin: Origin.extension;
} & MessageActions.Actions;

export type ClientMessage = {
    origin: Origin.client;
} & MessageActions.Actions;

export type SDKMessage = {
    origin: Origin.sdk;
} & MessageActions.Actions;

export enum ActionTypes {
    // misc
    pointingAtDesign = 'pointing-at-design',
    currentUrl = 'current-url',
    SDKError = 'SDK-error',
    stopAnimatedResizing = 'stop-animated-resizing',
    viewEnter = 'view-enter',
    demoPreviewLoaded = 'demo-preview-loaded',
    projectSelected = 'project-selected',
    windowResize = 'window-resize',
    // original styles
    turnOffOriginalStyles = 'turn-off-original-styles',
    turnOnOriginalStyles = 'turn-on-original-styles',
    // global styles
    turnOffGlobalStyles = 'turn-off-global-styles',
    turnOnGlobalStyles = 'turn-on-global-styles',
    refreshGlobalCss = 'refresh-global-css',
    // login
    userLoggedOut = 'user-logged-out',
    userLoggedIn = 'user-logged-in',
    // overlay
    hideOverlay = 'hide-overlay',
    showOverlay = 'show-overlay',
    // config
    getConfig = 'get-config',
    updateConfig = 'update-config',
    // element select
    selectElement = 'select-element',
    elementSelected = 'element-selected',
    selectedElement = 'selected-element',
    notSelectedElement = 'not-selected-element',
    stopSelectingElement = 'stop-selecting-element',
    // place pin
    placePin = 'place-pin',
    placePinClicked = 'place-pin-clicked',
    placePinCanceled = 'place-pin-canceled',
    placePinClear = 'place-pin-clear',
    // assets
    assetsGatheredError = 'assets-gathered-error',
    assetsGatheredSuccess = 'assets-gathered-success',
    assetsProcessing = 'assets-processing',
    assetsGathered = 'assets-gathered',
    // task
    gatherAssets = 'gather-assets',
    demoAnimateStyle = 'demo-animate-styler',
    // modifications
    selectorMatch = 'selectorMatch',
    selectorMatchCount = 'selectorMatchCount',
    modificationStatus = 'modificationStatus',
    modificationApplicationCount = 'modificationApplicationCount',
    modificationDiff = 'modificationDiff',
    getModificationDiff = 'getModificationDiff',
    // app
    appPosition = 'app-position',
    appSize = 'app-size',
    appClose = 'app-close',
    appSleep = 'app-sleep',
    appWakeUp = 'app-wake-up',
    appEnable = 'app-enable',
    appMode = 'app-mode',
    appInitialized = 'app-initialized',
    appDisconnect = 'app-disconnect',
    toggleApp = 'toggle-app',
    navigate = 'navigate',
    navigateRoute = 'navigateRoute',
    navigateSiteTo = 'navigateSiteTo',
    disableOverlay = 'disableOverlay',
    enableOverlay = 'enableOverlay',
    startMove = 'startMove',
    removeMove = 'removeMove',
    initPreview = 'initPreview',
    previewOn = 'previewOn',
    previewOff = 'previewOff',
    destroyPreview = 'destroyPreview',
}

// tslint:disable-next-line:no-namespace
export namespace MessageActions {
    export type Actions =
        | AppPosition
        | AppMode
        | RefreshGlobalCss
        | TurnOnGlobalStyles
        | TurnOffGlobalStyles
        | UserLoggedIn
        | UserLoggedOut
        | ShowOverlay
        | HideOverlay
        | ProjectSelected
        | AppEnabled
        | AppDisconnect
        | AppInitialized
        | GetConfig
        | AppWakeUp
        | StopAnimatedResizing
        | AppSleep
        | AssetsGathered
        | AssetsProcessing
        | SelectElement
        | AppClose
        | StopSelectingElement
        | ElementSelected
        | SDKError
        | ViewEnter
        | CurrentUrl
        | UpdateConfig
        | SelectedElement
        | NotSelectedElement
        | DemoPreviewLoaded
        | DemoSubmitted
        | PlacePinClicked
        | PlacePinCanceled
        | AssetsGatheredSuccess
        | AssetsGatheredError
        | TurnOffOriginalStyles
        | TurnOnOriginalStyles
        | PlacePin
        | WindowResize
        | PlacePinClear
        | GatherAssets
        | PointingAtDesign
        | ToggleApp
        | AppSize
        | Navigate
        | NavigateRoute
        | SelectorMatch
        | SelectorMatchCount
        | ModificationStatus
        | ModificationApplicationCount
        | ModificationDiff
        | GetModificationDiff
        | NavigateSiteTo
        | DisableOverlay
        | EnableOverlay
        | StartMove
        | RemoveMove
        | InitPreview
        | PreviewOn
        | PreviewOff
        | DestroyPreview;

    interface ModificationApplicationCount {
        action: ActionTypes.modificationApplicationCount;
        payload: {
            modificationId: string;
            applicationCount: number;
        };
    }

    interface ModificationStatus {
        action: ActionTypes.modificationStatus;
        modificationId: string;
        taskId?: string;
    }

    interface ModificationDiff {
        action: ActionTypes.modificationDiff;
        payload: {
            oldHtml: string;
            newHtml: string;
        };
    }

    interface GetModificationDiff {
        action: ActionTypes.getModificationDiff;
        modificationId: string;
    }

    interface SelectorMatch {
        action: ActionTypes.selectorMatch;
        selector: string;
    }

    interface SelectorMatchCount {
        action: ActionTypes.selectorMatchCount;
        payload: {
            count: number;
        };
    }

    interface Navigate {
        action: ActionTypes.navigate;
        payload: {
            url: string;
            appPath: string;
            appUrl: string;
        };
    }

    interface NavigateRoute {
        action: ActionTypes.navigateRoute;
        payload: {
            path: string;
            url: string;
        };
    }

    interface ToggleApp {
        action: ActionTypes.toggleApp;
    }

    interface WindowResize {
        action: ActionTypes.windowResize;
        payload: {
            width: number | string;
            height: number | string;
        };
    }

    interface AssetsGathered {
        action: ActionTypes.assetsGathered;
    }

    interface TurnOffOriginalStyles {
        action: ActionTypes.turnOffOriginalStyles;
    }

    interface TurnOnOriginalStyles {
        action: ActionTypes.turnOnOriginalStyles;
    }

    interface AppMode {
        action: ActionTypes.appMode;
        mode: App_Mode;
    }

    interface AppPosition {
        action: ActionTypes.appPosition;
        position: WindowPosition;
    }

    interface RefreshGlobalCss {
        action: ActionTypes.refreshGlobalCss;
    }

    interface TurnOffGlobalStyles {
        action: ActionTypes.turnOffGlobalStyles;
        updateConfig: boolean;
    }

    interface TurnOnGlobalStyles {
        action: ActionTypes.turnOnGlobalStyles;
        updateConfig: boolean;
    }

    interface UserLoggedIn {
        action: ActionTypes.userLoggedIn;
        accessToken: string;
    }

    interface UserLoggedOut {
        action: ActionTypes.userLoggedOut;
    }

    interface ShowOverlay {
        action: ActionTypes.showOverlay;
    }

    interface HideOverlay {
        action: ActionTypes.hideOverlay;
    }

    interface ProjectSelected {
        action: ActionTypes.projectSelected;
        projectId: string;
    }

    interface AppDisconnect {
        action: ActionTypes.appDisconnect;
    }

    interface AppInitialized {
        action: ActionTypes.appInitialized;
    }

    interface AppEnabled {
        action: ActionTypes.appEnable;
    }

    interface GetConfig {
        action: ActionTypes.getConfig;
    }

    interface AppWakeUp {
        action: ActionTypes.appWakeUp;
    }

    interface StopAnimatedResizing {
        action: ActionTypes.stopAnimatedResizing;
    }

    interface AppSleep {
        action: ActionTypes.appSleep;
    }

    interface AssetsProcessing {
        action: ActionTypes.assetsProcessing;
        payload: {
            phase: 0 | 1 | 2 | 3;
            total: number;
            processed: number;
            done: boolean;
        };
    }

    interface SelectElement {
        action: ActionTypes.selectElement;
    }

    interface DemoSubmitted {
        action: ActionTypes.demoAnimateStyle;
    }

    interface AppClose {
        action: ActionTypes.appClose;
    }

    interface StopSelectingElement {
        action: ActionTypes.stopSelectingElement;
    }

    interface ElementSelected {
        action: ActionTypes.elementSelected;
        selector: string;
    }

    interface SDKError {
        action: ActionTypes.SDKError;
        payload: ResponseError;
    }

    interface ViewEnter {
        action: ActionTypes.viewEnter;
        view:
            | 'report-issue'
            | 'new-task'
            | 'tasks'
            | 'home'
            | 'designs'
            | 'login'
            | 'settings'
            | 'modifications'
            | 'modification-detail';
    }

    interface GatherAssets {
        action: ActionTypes.gatherAssets;
        target?: StyleTarget;
    }

    interface PointingAtDesign {
        action: ActionTypes.pointingAtDesign;
        payload: {
            x: number;
            y: number;
            appX: number;
            appY: number;
        };
    }

    interface UpdateConfig {
        action: ActionTypes.updateConfig;
        config: SDKConfigType;
    }

    interface CurrentUrl {
        action: ActionTypes.currentUrl;
        url: string;
    }

    interface SelectedElement {
        action: ActionTypes.selectedElement;
        payload: {
            url: string;
            contentWidth: number;
        };
    }

    interface NotSelectedElement {
        action: ActionTypes.notSelectedElement;
    }

    interface DemoPreviewLoaded {
        action: ActionTypes.demoPreviewLoaded;
        payload: {
            documentWidth: unknown;
            documentHeight: unknown;
        };
    }

    interface AppSize {
        action: ActionTypes.appSize;
        size: UISize;
    }

    interface PlacePin {
        action: ActionTypes.placePin;
    }

    interface PlacePinClicked {
        action: ActionTypes.placePinClicked;
        elements: TaskElement[];
    }

    interface PlacePinCanceled {
        action: ActionTypes.placePinCanceled;
    }

    interface AssetsGatheredSuccess {
        action: ActionTypes.assetsGatheredSuccess;
        payload: {
            htmlIds: string[];
            context: PageContext;
        };
    }

    interface AssetsGatheredError {
        action: ActionTypes.assetsGatheredError;
    }

    interface PlacePinClear {
        action: ActionTypes.placePinClear;
    }

    interface NavigateSiteTo {
        action: ActionTypes.navigateSiteTo;
        payload: { url: string; appPath: string; appUrl: string };
    }

    interface DisableOverlay {
        action: ActionTypes.disableOverlay;
    }

    interface EnableOverlay {
        action: ActionTypes.enableOverlay;
    }

    interface StartMove {
        action: ActionTypes.startMove;
    }
    interface RemoveMove {
        action: ActionTypes.removeMove;
    }

    interface InitPreview {
        action: ActionTypes.initPreview;
        payload: { taskId: string };
    }
    interface PreviewOn {
        action: ActionTypes.previewOn;
    }
    interface PreviewOff {
        action: ActionTypes.previewOff;
    }

    interface DestroyPreview {
        action: ActionTypes.destroyPreview;
    }
}

export enum Origin {
    assetManager = 'stylers-cloud-asset-manager',
    sdk = 'stylers-cloud-sdk',
    client = 'stylers-cloud-client',
    extension = 'stylers-cloud-extension',
    platform = 'stylers-cloud-platform',
    replica = 'stylers-cloud-replica',
    domagic = 'stylers-cloud-domagic',
    sassWorker = 'stylers-cloud-sass-worker',
}
