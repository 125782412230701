import { EventEmitter, Injectable, Renderer2 } from '@angular/core';
import { ResizeService } from './resize.service';
import { DemoService } from './demo.service';
import { ProjectService } from './project.service';
import { ActionTypes, Message } from '../../../shared/types/message.types';
import { AppPath, NavigateService } from './navigate.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessageReceiverService {
    private emmiter = new Subject<Message>();

    public messages = this.emmiter.asObservable();

    constructor(
        private resize: ResizeService,
        private demo: DemoService,
        private project: ProjectService,
        private navigateService: NavigateService,
    ) {}

    init(renderer: Renderer2) {
        renderer.listen('window', 'message', this.handleMessages.bind(this));
    }

    addEventListener(handler: any) {
        const subscription = this.emmiter.subscribe(handler);
        handler.unsubscribe = subscription.unsubscribe.bind(subscription);
    }

    removeEventListener(handler: any) {
        if (handler.unsubscribe) {
            handler.unsubscribe();
        }
    }

    handleMessages(message: MessageEvent<Message>) {
        // from SDK
        if (message.data.origin === 'stylers-cloud-sdk') {
            switch (message.data.action) {
                case ActionTypes.navigateRoute:
                    const { path, url } = message.data.payload;
                    this.navigateService.to(path as AppPath, url);
                    break;
                case ActionTypes.updateConfig:
                    this.project.updateConfig(message.data.config);
                    break;

                case ActionTypes.currentUrl:
                    this.project.currentUrl = message.data.url;
                    break;

                case ActionTypes.selectedElement:
                    this.demo.update(message.data.payload);
                    break;

                case ActionTypes.notSelectedElement:
                    // ????
                    break;

                case ActionTypes.demoPreviewLoaded:
                    this.demo.resize(message.data.payload.documentWidth, message.data.payload.documentHeight);
                    break;

                // handled individualy in other components
                case ActionTypes.placePinClicked:
                case ActionTypes.placePinCanceled:
                case ActionTypes.SDKError:
                case ActionTypes.elementSelected:
                case ActionTypes.assetsGatheredError:
                case ActionTypes.assetsGatheredSuccess:
                case ActionTypes.assetsProcessing:
                    break;

                default:
                    console.log('unhandled in-app message:', message);
            }
        }

        // self listening
        if (message.data.origin === 'stylers-cloud-client') {
            if (message.data.action === ActionTypes.appSize) {
                this.resize.update();
            }
        }

        // let know all the registred listeners
        this.emmiter.next(message.data);
    }
}
