import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    HostBinding,
    HostListener,
    ElementRef,
    NgZone,
} from '@angular/core';
import { toBoolean } from '../../helpers/to-boolean';

@Component({
    selector: 'ui-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class IconButtonComponent implements OnInit {
    @HostBinding('class.ui-form-item') formItem = true;

    @HostBinding('attr.disabled')
    private _disabled = false;

    @HostBinding('attr.icon')
    @Input()
    icon: string;

    @HostBinding('attr.role') role = 'button';
    @HostBinding('attr.tabindex') tabIndex = 0;

    constructor(private componentElement: ElementRef, private zone: NgZone) {}

    ngOnInit() {}

    // disabled
    //     true: disabled | disabled="true" | disabled="required" | disabled="any value" | [disabled]="true"
    //     false: no attribute | disabled="false" | [disabled]="false" | [disabled]
    @Input()
    public set disabled(val: any) {
        this._disabled = toBoolean(val);
        this.tabIndex = this._disabled ? -1 : null;
    }
    public get disabled(): any {
        return this._disabled;
    }

    @HostListener('mousedown', ['$event'])
    private _onMouseDownHandler(event: MouseEvent) {
        this.zone.runOutsideAngular(() => {
            this.componentElement.nativeElement.classList.add('internal--pressed');
        });
    }

    @HostListener('mouseup', ['$event'])
    @HostListener('mouseleave', ['$event'])
    private _onMouseReleaseHandler(event: MouseEvent) {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.componentElement.nativeElement) {
                    this.componentElement.nativeElement.classList.remove('internal--pressed');
                }
            }, 250);
        });
    }
}
