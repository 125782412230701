import { Injectable } from '@angular/core';
import { MessageSenderService } from './message-sender.service';
import { ProjectService } from './project.service';
import { AppPath } from './navigate.service';
import { ActionTypes } from '../../../shared/types/message.types';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class ResizeService {
    lastWidth: number | string;
    lastHeight: number | string;

    private pageSizes: { [key: string]: { width: number | string; height: number | string } } = {
        '/login': { width: 340, height: 385 },

        '/home': { width: 340, height: 265 },

        '/init': { width: 340, height: 535 },

        '/settings': { width: 340, height: 560 },

        '/tasks': { width: 780, height: 550 },

        '/tasks/new': { width: 490, height: 420 },
        '/tasks/new#minimized': { width: 224, height: 43 }, // minimized when placing pin

        '/tasks/detail': { width: 1080, height: 550 },
        '/tasks/preview': { width: 181, height: 69 },

        '/report-issue': { width: 490, height: 508 },
        '/report-issue#from-task': { width: 490, height: 508 }, // no difference (it was longer because "emotion" block)
        '/report-issue#minimized': { width: 224, height: 43 }, // minimized when placing pin

        '/designs': { width: 490, height: 575 },

        '/showcase': { width: '100%', height: '100%' },
        '/styles': { width: 985, height: 550 },

        '/modifications': { width: 520, height: 620 },
        '/modifications/detail': { width: 640, height: 620 },
        '/modification-diff': { width: '100%', height: '100%' },
    };

    pageSizeSupportMode: { [key: string]: { width: number | string; height: number | string } } = {
        '/home': { width: 340, height: 158 },
        '/settings': { width: 340, height: 500 },
        '/tasks': { width: 590, height: 550 },
        '/modification-diff': { width: '100%', height: '100%' },
    };

    constructor(
        private message: MessageSenderService,
        private project: ProjectService,
        private configService: ConfigService,
    ) {}

    set(width: number | string, height: number | string) {
        this.lastWidth = width;
        this.lastHeight = height;
        const koef = { small: 0.8125, medium: 1, large: 1.1875 };
        const size = koef[this.project.uiSize];

        // if standalone app (in own window)
        if (window.parent === window) {
            const s = document.querySelector('ion-app').style;
            s.width =
                width.toString().indexOf('%') > 0
                    ? width.toString()
                    : (parseInt(width as string, 10) * size).toFixed(2) + 'px';
            s.height =
                height.toString().indexOf('%') > 0
                    ? height.toString()
                    : (parseInt(height as string, 10) * size).toFixed(2) + 'px';
        }
        // if in iframe
        else {
            this.message.post({ action: ActionTypes.windowResize, payload: { width, height } });
        }
    }

    getWidth() {
        return this.lastWidth;
    }

    getHeight() {
        return this.lastHeight;
    }

    update() {
        this.set(this.lastWidth, this.lastHeight);
    }

    reset() {
        this.setByPath('/home');
    }

    setByPath(path: AppPath) {
        const size = this.getByPath(path);

        if (size) {
            this.set(size.width, size.height);
        }
    }

    getByPath(path: AppPath) {
        let size = this.pageSizes[path];

        if (!!this.configService.getConfig()?.supportModeOnly && this.pageSizeSupportMode[path]) {
            size = this.pageSizeSupportMode[path];
        }

        return size;
    }
}
