import { Injectable } from '@angular/core';

// based on https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Content_categories#Form_labelable
const selector = 'button, input, keygen, meter, output, progress, select, textarea';

@Injectable({
    providedIn: 'root',
})
export class LabelableTargetResolverService {
    findTarget(rootElement: Element) {
        const match = rootElement.querySelector(selector) as HTMLElement;
        if (match && (match as HTMLInputElement).readOnly !== true && (match as HTMLInputElement).disabled !== true) {
            return match as HTMLElement;
        }

        return null;
    }
}
