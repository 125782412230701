import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClientPageHeaderComponent } from './page-header/page-header.component';
import { ClientCloseButtonComponent } from './close-button/close-button.component';
import { ClientBackButtonComponent } from './back-button/back-button.component';
import { ClientIconButtonComponent } from './icon-button/icon-button.component';
import { DirectivesModule } from '../directives/directives.module';
import { ClientGridComponent } from './grid/grid.component';
import { RouterModule } from '@angular/router';
import { UiModule } from './ui.module';
import { RelativeDatePipe } from '../pipes/relative-date.pipe';
import { ClientButtonComponent } from './button/button.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IsOneOfModificationStatesPipe } from '../pages/modification-detail/pipes/is-one-of-modification-states.pipe';

const uiComponents = [
    ClientPageHeaderComponent,
    ClientCloseButtonComponent,
    ClientBackButtonComponent,
    ClientIconButtonComponent,
    ClientGridComponent,
    ClientButtonComponent,
    RelativeDatePipe,
];

@NgModule({
    declarations: [...uiComponents, IsOneOfModificationStatesPipe],
    exports: [...uiComponents, UiModule, IsOneOfModificationStatesPipe],
    imports: [CommonModule, DirectivesModule, FormsModule, RouterModule, UiModule, ScrollingModule],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiClientModule {}

// ionic generate component ui/COMPONENT_NAME --prefix=ui
