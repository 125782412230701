import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Pagination } from '../shared/types/app.types';
import { ListMeta, Response } from '../shared/types/http-response.types';
import { UserRoles } from '../shared/types/user.types';
import { EntityType, Message, NewMessage, MessageFilter } from './messaging.types';
import { InjectionToken } from '@angular/core';
import { forkJoin, of } from 'rxjs';

export const API_BASE_URL = new InjectionToken('API_BASE_URL');

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private http: HttpClient, @Inject(API_BASE_URL) private baseUrl: string) {
        if (baseUrl === null || baseUrl === undefined) {
            console.error('Missing API_BASE_URL provider.');
        }
    }

    createMessage(message: NewMessage, projectId: string, entityType: EntityType, entityId: string, mode: UserRoles) {
        return this.http.post(`${this.baseUrl}v1/projects/${projectId}/messages/${entityType}/${entityId}`, message, {
            params: {
                mode,
            },
        });
    }

    fetch(
        projectId: string,
        entityType: EntityType,
        entityId: string,
        filter?: MessageFilter,
        pagination?: Pagination,
        mode = UserRoles.styler,
    ) {
        let params = new HttpParams({
            fromObject: {
                mode,
            },
        });

        Object.keys(filter || {}).forEach(key => {
            const value = Array.isArray(filter[key])
                ? filter[key].join(',')
                : filter[key] !== null && filter[key] !== undefined
                ? filter[key]
                : '';

            params = params.append(`filter[${key}]`, value);
        });

        if (pagination) {
            params = params.append('page[size]', pagination.size + '');
            params = params.append('page[number]', pagination.number + '');
        }

        return this.http.get<Response<Message[], ListMeta>>(
            `${this.baseUrl}v1/projects/${projectId}/messages/${entityType}/${entityId}`,
            {
                params,
            },
        );
    }

    updateMessage(
        message: Message,
        updateMessage: { isRead: boolean },
        projectId: string,
        entityType: EntityType,
        entityId: string,
        mode: UserRoles,
    ) {
        return this.http.patch(
            `${this.baseUrl}v1/projects/${projectId}/messages/${entityType}/${entityId}/${message.id}`,
            updateMessage,
            {
                params: { mode },
            },
        );
    }

    markMessagesAsRead(
        messages: Message[],
        projectId: string,
        entityType: EntityType,
        entityId: string,
        mode: UserRoles,
    ) {
        const requests = messages.map(message =>
            this.updateMessage(message, { isRead: true }, projectId, entityType, entityId, mode).pipe(
                catchError(e => of('nah')),
            ),
        );

        return forkJoin(requests);
    }
}

export const isCurrentUserMessage = (
    message: Message,
    currentType: Message['type'],
    currentUser: Message['author'],
) => {
    // hotfix for client app
    if (!message.author) {
        return currentType === 'external' && message.authorRole === 'customer';
    }

    return currentUser.id === message.author.id;
};
