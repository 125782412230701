<scp-message-item
    *ngFor="let message of messages"
    [class.right]="isCurrentUserMessage(message) || isCurrentMessageOurs(message)"
    [message]="message"
    [isCurrentUserMessage]="isCurrentUserMessage(message)"
    [markable]="readStatus === 'markable' && !isCurrentMessageOurs(message)"
    [readStatus]="readStatus"
    (markAsRead)="changeReadStatus(message)"
>
</scp-message-item>

<div
    *ngIf="messages?.length === 0"
    class="no-messages"
>no messages yet</div>
