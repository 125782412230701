<ui-input
    class="full-width-with-space-right"
    readonly="true"
></ui-input>
<ui-icon-button
    icon="chevron-down-sharp"
    class="absolute-align-right small"
></ui-icon-button>
<span class="select-content">
    <ng-content></ng-content>
</span>
