<span
    *ngIf="editable && !isEditing"
    class="input-value"
>{{ value }}</span>
<div class="input-content-wrapper" *ngIf="!editable || editable && isEditing"><!--
--><span class="input"><input
        *ngIf="type=='text' || type=='password' || type=='email' || type=='number'"
        #formInput
        [type]="type"
        class="input-element"
        [(ngModel)]="value"
        [style.width]="editable ? editableTextWidth.offsetWidth + 'px' : ''"
        [attr.id]="id"
        [attr.name]="name"
        [attr.placeholder]="placeholder"
        [attr.required]="required ? '' : null"
        [attr.disabled]="disabled ? '' : null"
        [attr.readonly]="readonly ? '' : null"
        [class.focused]="focused"
        [attr.maxlength]="maxlength"
        (blur)="onBlur($event)"
        (focus)="onInnerInputFocus($event)"
    ><textarea
        *ngIf="type=='textarea'"
        #formInput
        class="input-element"
        [rows]="rows"
        [(ngModel)]="value"
        [attr.id]="id"
        [attr.name]="name"
        [attr.placeholder]="placeholder"
        [attr.required]="required ? '' : null"
        [attr.disabled]="disabled ? '' : null"
        [attr.readonly]="readonly ? '' : null"
        [class.focused]="focused"
        (blur)="onInnerInputBlur($event)"
        (focus)="onInnerInputFocus($event)"
    ></textarea></span><!--
--></div>
<img
    *ngIf="editable && !isEditing"
    class="pencil"
    src="/assets/images/icon-pencil-white.png"
    (click)="onEdit()"
/>
<span
    #editableTextWidth
    class="text-width-helper"
>{{ value }}</span>
