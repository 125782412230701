import { Component, ElementRef, HostBinding, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { LabelableTargetResolverService } from './labelable-target-resolver.service';

@Component({
    selector: 'ui-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ItemComponent {
    @Input() label?: string;
    @Input() for?: string;
    @ViewChild('content', { static: true })
    private content: ElementRef;

    @HostBinding('style.width')
    @HostBinding('style.flex-basis')
    @Input()
    size?: string;

    // tslint:disable-next-line:no-input-rename
    @Input('content-size')
    contentSize?: string;

    @Input()
    order?: string;

    /** Set class name "without-label" when no label. */
    @HostBinding('class.without-label')
    private get withoutLabel() {
        return !this.hasLabel();
    }

    @HostBinding('attr.label-position')
    // tslint:disable-next-line:no-input-rename
    @Input('label-position')
    labelPosition: 'above' | 'before' | 'after' = 'above';

    @Input()
    tooltip?: string;

    @HostBinding('class.internal--focused')
    private isInnerInputFocused = false;

    constructor(private labelableTargetResolver: LabelableTargetResolverService) {}

    /**
     * Simulates the click propagated by native label.
     */
    onLabelTextClick(event: MouseEvent) {
        const target = this.labelableTargetResolver.findTarget(this.content.nativeElement);
        event.stopPropagation();
        if (target) {
            target.dispatchEvent(new Event('click', { bubbles: true })); // common click() does not work in Safari
            target.focus();
        }
    }

    /** Returns item label. */
    public getLabel() {
        return this.label;
    }

    /** Returns if item has label. */
    public hasLabel() {
        // !! because can be undefined (not only empty string)
        return !!this.label;
    }

    public onInnerItemFocus() {
        this.isInnerInputFocused = true;
    }

    public onInnerItemChangeValue(value: any) {
        // this.labelFloatAbove = !!value || this.isInnerInputFocused;
    }

    public onInnerItemBlur(value: any) {
        this.isInnerInputFocused = false;
    }
}
