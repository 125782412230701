import { Router, RoutesRecognized } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PreviousRouteService {
    private previousRoute: string;
    private currentRoute: string;

    constructor(private router: Router) {
        router.events
            .pipe(
                filter((evt: any) => evt instanceof RoutesRecognized),
                pairwise(),
            )
            .subscribe((events: RoutesRecognized[]) => {
                this.previousRoute = events[0].urlAfterRedirects;
                this.currentRoute = events[1].urlAfterRedirects;
            });
    }

    getPreviousRoute() {
        return this.previousRoute;
    }
}
