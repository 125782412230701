export function loadRefreshToken() {
    try {
        return localStorage.getItem('refreshToken');
    } catch (e) {
        if (e instanceof DOMException && e.INVALID_ACCESS_ERR) {
            throw "stylers.cloud snippet in console doesn't work in incognito mode for some pages";
        }
        throw e;
    }
}
