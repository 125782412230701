<table class="ui-grid-table">
    <thead class="ui-grid-header">
        <tr class="ui-grid-header-row">
            <th
                *ngFor="let formatColumn of format"
                class="ui-grid-header-cell"
                [style.width]="formatColumn.size"
                [class.asc]="isSortedAsc(formatColumn.key)"
                [class.desc]="isSortedDesc(formatColumn.key)"
                (click)="onTableHeaderClick(formatColumn.key)"
            >
                <div class="ui-grid-header-cell-wrapper">
                    <app-button
                        class="small centered full-width no-outer-glow sharp-corners {{ formatColumn.extraClass }}"
                        [align]="formatColumn.align || 'left'"
                        [disabled]="formatColumn.disabled"
                        [icon]="getSortIcon(formatColumn.key)"
                    >{{ formatColumn.name!==undefined ? formatColumn.name : formatColumn.key }}</app-button>
                </div>
            </th>
        </tr>
    </thead>
</table>

<ng-container *ngIf="data; else loadingTable">
    <ng-container *ngIf="data.length > 0; else emptyTable">
        <cdk-virtual-scroll-viewport itemSize="30">
            <table class="ui-grid-table ui-grid-table-data">
                <tbody class="ui-grid-body">
                    <tr
                        class="ui-grid-body-row {{ item.extraClass }}"
                        *cdkVirtualFor="let item of data; let i = index; trackBy: trackBy"
                        (click)="navigateToDetail(item.id)"
                        [class.highlight]="item.id===highlightId"
                    >
                        <!-- HOTFIX bolo to tu natvrdo treba to prerobit do buducna -->
                        <ng-container *ngFor="let formatColumn of format">
                            <td
                                *ngIf="formatColumn.key === 'name'"
                                class="ui-grid-body-cell {{ formatColumn.extraClass }}"
                                [style.width]="formatColumn.size"
                                [attr.align]="formatColumn.align || 'left'"
                                [title]="item.name"
                                [class.has-parent]="item.hasParent"
                            >
                                <ui-state-icon
                                    [type]="item.type"
                                    [state]="item.state"
                                    [compositeState]="item.compositeState"
                                    class="larger space-after"
                                ></ui-state-icon>{{ item.name }}
                            </td>
                            <td
                                *ngIf="formatColumn.key === 'type'"
                                class="ui-grid-body-cell {{ formatColumn.extraClass }}"
                                [style.width]="formatColumn.size"
                                [attr.align]="formatColumn.align || 'left'"
                                [title]="item.type"
                                [attr.align]="'center'"
                            >{{ item.parentTask ? '(sub)' + item.type : item.type}}</td>
                            <td
                                *ngIf="formatColumn.key === 'state'"
                                class="ui-grid-body-cell {{ formatColumn.extraClass }}"
                                [style.width]="formatColumn.size"
                                [attr.align]="formatColumn.align || 'left'"
                                [title]="item.state"
                                [attr.align]="'center'"
                            >{{ item.state }}</td>
                            <td
                                *ngIf="formatColumn.key === 'urgency'"
                                class="ui-grid-body-cell {{ formatColumn.extraClass }}"
                                [style.width]="formatColumn.size"
                                [attr.align]="formatColumn.align || 'left'"
                                [title]="urgencyDeliveryTimeLabels[item.urgency]"
                                [attr.align]="'center'"
                            >{{ urgencyLabels[item.urgency] }}</td>
                            <td
                                *ngIf="formatColumn.key === 'createdDatetime'"
                                class="ui-grid-body-cell {{ formatColumn.extraClass }}"
                                [style.width]="formatColumn.size"
                                [attr.align]="formatColumn.align || 'left'"
                                [title]="item.createdDatetime | date:'MMM d, y, HH:MM'"
                                [attr.align]="'center'"
                            >{{ item.createdDatetime | relativeDate }}</td>
                            <td
                                *ngIf="formatColumn.key === 'createdBy'"
                                class="ui-grid-body-cell {{ formatColumn.extraClass }}"
                                [style.width]="formatColumn.size"
                                [attr.align]="formatColumn.align || 'left'"
                                [title]="item.createdBy.nickname + ' - ' + item.createdBy.email"
                            >{{ item.createdBy.nickname }}</td>

                        </ng-container>
                        <!--                <td-->
                        <!--                    *ngFor="let formatColumn of format"-->
                        <!--                >-->
                        <!--                    <ng-container *ngIf="!formatColumn.templateRef; else templateRef">-->
                        <!--                        {{ item[formatColumn.key] }}-->
                        <!--                    </ng-container>-->
                        <!--                    <ng-template-->
                        <!--                        #templateRef-->
                        <!--                        [ngTemplateOutlet]="formatColumn.templateRef"-->
                        <!--                        [ngTemplateOutletContext]="{ $implicit: item, index: i }"-->
                        <!--                    ></ng-template>-->
                        <!--                </td>-->
                    </tr>
                </tbody>
            </table>
        </cdk-virtual-scroll-viewport>
    </ng-container>
</ng-container>

<ng-template #loadingTable>
    <table class="ui-grid-table ui-grid-table-data empty-table">
        <tbody class="ui-grid-body">
            <tr class="ui-grid-body-row">
                <td
                    class="ui-grid-body-cell grid-empty-table grid-no-padding"
                    [attr.colspan]="format.length"
                >
                    <ui-loader class="centered"></ui-loader>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>

<ng-template #emptyTable>
    <table class="ui-grid-table ui-grid-table-data empty-table">
        <tbody class="ui-grid-body">
            <tr class="ui-grid-body-row empty-table">
                <td
                    class="ui-grid-body-cell grid-empty-table"
                    [attr.colspan]="format.length"
                >
                    There are no items in this list.
            </tr>
        </tbody>
    </table>
</ng-template>