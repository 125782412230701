import { Animation, AnimationController } from '@ionic/angular';

export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
    const DURATION = 350;

    const animationCtrl = new AnimationController();

    // opts.enteringEl.classList.add('animating');
    // opts.leavingEl.classList.add('animating');

    // if (opts.direction === 'forward') {
    //     opts.enteringEl.classList.add('sliding');

    //     const leavingAnimation = animationCtrl.create()
    //         .addElement(opts.leavingEl)
    //         .duration(DURATION * 0.9)
    //         .easing('ease-in')
    //         // .fromTo('transform', 'translateX(0)', 'translateX(-50%)')
    //         .fromTo('filter', 'grayscale(0) brightness(1)', 'grayscale(1) brightness(0)')
    //         .onFinish(() => {
    //             opts.leavingEl.classList.remove('animating');
    //         });

    //     // opts.enteringEl.classList.add('animate-from-left');
    //     // setTimeout(() => opts.enteringEl.classList.remove('animate-from-left'), 0);

    //     const rootAnimation = animationCtrl.create()
    //             .addElement(opts.enteringEl)
    //             .duration(DURATION)
    //             .easing('ease-out')
    //             .fromTo('opacity', 1, 1)
    //             // .fromTo('transform', 'translateX(105%)', 'translateX(0)')
    //             .fromTo('transform', 'translate3d(108%,0,0)', 'translate3d(0,0,0)')
    //             .onFinish(() => {
    //                 opts.enteringEl.classList.remove('animating');
    //                 opts.enteringEl.classList.remove('sliding');
    //             });

    //     return animationCtrl.create().addAnimation([rootAnimation, leavingAnimation]);
    // }
    // else {
    //     const rootAnimation = animationCtrl.create()
    //         .addElement(opts.enteringEl)
    //         .duration(DURATION)
    //         .easing('ease-out')
    //         // .fromTo('transform', 'translateX(-50%)', 'translateX(0%)')
    //         .fromTo('filter', 'grayscale(1) brightness(0)', 'grayscale(0) brightness(1)')
    //         .fromTo('opacity', 1, 1)
    //         .onFinish(() => {
    //             opts.enteringEl.classList.remove('animating');
    //         });

    //     opts.leavingEl.classList.add('sliding');

    //     const leavingAnimation = animationCtrl.create()
    //         .addElement(opts.leavingEl)
    //         .duration(DURATION)
    //         .easing('ease-in')
    //         .fromTo('opacity', 1, 1)
    //         // .fromTo('transform', 'translateX(0)', 'translateX(105%)')
    //         .fromTo('transform', 'translate3d(0,0,0)', 'translate3d(108%,0,0)')
    //         .onFinish(() => {
    //             opts.leavingEl.classList.remove('animating');
    //             opts.leavingEl.classList.remove('sliding');
    //         });

    //     return animationCtrl.create().addAnimation([rootAnimation, leavingAnimation]);
    // }

    // ------------------------------------------------------------------

    const DELAY_AFTER = 200;

    opts.enteringEl.classList.add('animating');
    opts.leavingEl.classList.add('animating');

    if (opts.direction === 'forward') {
        opts.enteringEl.classList.add('sliding');

        opts.enteringEl.classList.add('animate-from-left');
        setTimeout(() => {
            opts.enteringEl.classList.remove('animate-from-left');
            opts.leavingEl.classList.add('animate-fade-out');
        }, 0);
        opts.leavingEl.style.display = 'none';

        const leavingAnimation = animationCtrl
            .create()
            .addElement(opts.leavingEl)
            .delay(DURATION)
            .duration(0)
            // .fromTo('opacity', 1, 0)
            .fromTo('marginRight', '0px', '1px')
            .onFinish(() => {
                setTimeout(() => {
                    opts.leavingEl.classList.remove('animating');
                    opts.leavingEl.classList.remove('animate-fade-out');
                    opts.enteringEl.classList.remove('sliding');
                }, DELAY_AFTER);
            });

        const rootAnimation = animationCtrl
            .create()
            .addElement(opts.enteringEl)
            .delay(DURATION)
            .duration(0)
            // .fromTo('opacity', 0, 1)
            .fromTo('marginRight', '0px', '1px')
            .onFinish(() => {
                setTimeout(() => {
                    opts.enteringEl.classList.remove('animating');
                }, DELAY_AFTER);
            });

        return animationCtrl.create().addAnimation([rootAnimation, leavingAnimation]);
    } else {
        opts.leavingEl.classList.add('sliding');

        opts.enteringEl.classList.add('animate-fade-out');
        setTimeout(() => {
            opts.leavingEl.classList.add('animate-from-left');
            opts.enteringEl.classList.remove('animate-fade-out');
        }, 0);

        const rootAnimation = animationCtrl
            .create()
            .addElement(opts.enteringEl)
            .delay(DURATION)
            .duration(0)
            // .fromTo('opacity', 0, 1)
            .fromTo('marginRight', '0px', '1px')
            .onFinish(() => {
                setTimeout(() => {
                    opts.enteringEl.classList.remove('animating');
                    opts.leavingEl.classList.remove('sliding');
                }, DELAY_AFTER);
            });

        const leavingAnimation = animationCtrl
            .create()
            .addElement(opts.leavingEl)
            .delay(DURATION)
            .duration(0)
            // .fromTo('opacity', 1, 0)
            .fromTo('marginRight', '0px', '1px')
            .onFinish(() => {
                setTimeout(() => {
                    opts.leavingEl.classList.remove('animating');
                    opts.leavingEl.classList.remove('animate-from-left');
                }, DELAY_AFTER);
            });

        return animationCtrl.create().addAnimation([rootAnimation, leavingAnimation]);
    }
};
