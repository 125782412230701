import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'ui-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit {
    @Input() description?: string;

    constructor() {}

    ngOnInit() {}

    createRange(num: number) {
        const items: number[] = [];
        for (let i = 0; i < num; i++) {
            items.push(i);
        }
        return items;
    }
}
