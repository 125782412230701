import { Injectable } from '@angular/core';
import { Design } from '../../../shared/types/designs.types';

@Injectable({
    providedIn: 'root',
})
export class DemoService {
    constructor() {}

    /** Flag if element is already selected. */
    activated = false;
    /** Clients page content width in px. */
    contentWidth = 0;
    /** Clients page content height in px. */
    contentHeight = 0;
    /** Clients page current URL address. */
    clientsURL = '';
    /** Flag if area in design was already selected. */
    isAreaSelected = false;
    /** Choosen design */
    choosenDesign = '';
    /** Choosen design object */
    choosenDesignObject: Design = null;

    update(data) {
        this.activated = true;
        this.contentWidth = data.contentWidth;
        this.clientsURL = data.url;
        this.isAreaSelected = false;
    }

    deactivate() {
        this.activated = false;
        this.isAreaSelected = false;
        this.clientsURL = '';
        this.choosenDesign = '';
        this.choosenDesignObject = null;
        this.contentWidth = 0;
        this.contentHeight = 0;
    }

    resize(width, height) {
        this.contentWidth = width;
        this.contentHeight = height;
    }

    hasSize() {
        return this.contentWidth !== 0 && this.contentHeight !== 0;
    }

    test() {
        this.activated = true;
        this.clientsURL = 'https://perrytalents.sk/';
        this.contentWidth = 1519;
    }
}

// Message data:
// action: "demo-selected-element"
// contentWidth: 707
// origin: "stylers-cloud-sdk"
// url: "https://perrytalents.sk/lektori"
