import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    HostListener,
    HostBinding,
    Optional,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
    NgZone,
} from '@angular/core';
import { GenericInputComponent } from '../input/generic-input-component';
import { ItemComponent } from '../item/item.component';

@Component({
    selector: 'ui-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent extends GenericInputComponent implements OnInit {
    @ViewChild('formInput', { static: true })
    element: ElementRef;

    @HostBinding('class.ui-form-item') formItem = true;

    @HostBinding('attr.icon')
    @HostBinding('class.internal--has-icon')
    @Input()
    icon: string;

    @HostBinding('class.internal--activated')
    private _activated = false;

    @Input()
    public set active(val: boolean) {
        if (val === true) {
            this.onActivate.emit(this);
        }
        this._activated = val;
    }
    public get active(): boolean {
        return this._activated;
    }

    @HostBinding('attr.role') role = 'button';
    @HostBinding('attr.tabindex') tabIndex = 0;

    @Input()
    // @ts-ignore
    // TODO check with @Seyd
    value: any;

    /** With icon of check mark. */
    @HostBinding('attr.checked')
    @Input()
    checked = false;

    @HostBinding('attr.priority')
    @Input()
    priority: 'normal' | 'primary' | 'secondary' | 'positive' | 'negative' = 'normal';

    @Output()
    onClick: EventEmitter<this> = new EventEmitter();

    @Output()
    onActivate: EventEmitter<this> = new EventEmitter();

    constructor(
        changeDetector: ChangeDetectorRef,
        private componentElement: ElementRef,
        private zone: NgZone,
        @Optional() public parentItem?: ItemComponent,
    ) {
        super(changeDetector, parentItem);
    }

    ngOnInit() {}

    @HostListener('click', ['$event'])
    _onClickHandler(event: MouseEvent) {
        this.onClick.emit(this);
    }

    @HostListener('mousedown', ['$event'])
    private _onMouseDownHandler(event: MouseEvent) {
        this.zone.runOutsideAngular(() => {
            this.componentElement.nativeElement.classList.add('internal--pressed');
        });
    }

    @HostListener('mouseup', ['$event'])
    @HostListener('mouseleave', ['$event'])
    private _onMouseReleaseHandler(event: MouseEvent) {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.componentElement.nativeElement) {
                    this.componentElement.nativeElement.classList.remove('internal--pressed');
                }
            }, 250);
        });
    }

    activate() {
        this._activated = true;
    }

    deactivate() {
        this._activated = false;
    }
}
