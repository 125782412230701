<div class="content-wrapper">

    <div class="element expanded"><span class="icon"></span><span class="tag-open">html
        <span class="attribute">class="</span><span class="value-class">desktop</span><span class="attribute-end">"</span>
        <span class="attribute">lang="</span><span class="value">en</span><span class="attribute-end">"</span></span>
        <span class="inner-content">    
            <div class="element collapsed"><span class="icon"></span><span class="tag-open">head</span><span class="inner-content"></span><span class="tag-close">head</span></div>
            <div class="element collapsed"><span class="icon"></span><span class="tag-open">body <span class="attribute">class="</span><span class="value-class">animate-intro</span><span class="attribute-end">"</span></span><span class="inner-content"></span><span class="tag-close">body</span></div>
        </span>
    <span class="tag-close">html</span></div>
    
</div>