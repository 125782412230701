import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    InjectionToken,
    Input,
    Output,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ItemComponent } from '../item/item.component';
import { InlineEditTarget } from './inline-edit-target';
import { toBoolean } from '../../helpers/to-boolean';

const noop = () => {};

@Component({
    template: '',
})
export abstract class GenericInputComponent implements ControlValueAccessor, InlineEditTarget {
    abstract element: ElementRef;

    @HostBinding('class.ui-form-item') formItem = true;

    // when inner input has focus, outer element (component) gets class .focused
    @HostBinding('class.focused')
    @Input() // todo get rid of input only used in sandbox
    focused = false;

    // required
    //     true: required | required="true" | required="required" | required="any value" | [required]="true"
    //     false: no attribute | required="false" | [required]="false" | [required]
    @Input()
    public set required(val: any) {
        this._required = toBoolean(val);
    }

    public get required(): any {
        return this._required;
    }

    @HostBinding('class.required') private _required = false;

    // disabled
    //     true: disabled | disabled="true" | disabled="required" | disabled="any value" | [disabled]="true"
    //     false: no attribute | disabled="false" | [disabled]="false" | [disabled]
    @Input()
    public set disabled(val: any) {
        this._disabled = toBoolean(val);
    }

    public get disabled(): any {
        return this._disabled;
    }

    @HostBinding('attr.disabled')
    @HostBinding('class.disabled')
    private _disabled = false;

    // readonly
    //     true: readonly | readonly="true" | readonly="required" | readonly="any value" | [readonly]="true"
    //     false: no attribute | readonly="false" | [readonly]="false" | [readonly]
    @Input()
    public set readonly(val: any) {
        this._readonly = toBoolean(val);
    }

    public get readonly(): any {
        return this._readonly;
    }

    @HostBinding('class.readonly') private _readonly = false;

    // --------------------------------------------------------------------------
    // Public interface and business logic --------------------------------------
    // --------------------------------------------------------------------------

    @Input() id?: string;
    @Input() name?: string;
    @Input() placeholder?: string;
    // todo remove partialId since it is not used
    @Input() partialId?: string;
    @Input() showcaseMode = false;
    @Input() autocomplete: string; // Type string because there is also value "new-password", so we will pass html style value.

    @HostBinding('style.max-width')
    @Input()
    size?: string;

    /** Variation for input without borders which looks like label (pencil icon indicates option to edit) */
    @Input() editableLabel = false;

    // tslint:disable-next-line:no-output-rename
    @Output('focus') public focusEmitter: EventEmitter<FocusEvent> = new EventEmitter();
    // tslint:disable-next-line:no-output-rename
    @Output('blur') public blurEmitter: EventEmitter<FocusEvent> = new EventEmitter();
    // tslint:disable-next-line:no-output-rename

    private _value: string = null;

    private onChangeCallback: (_: any) => void = noop;
    private onTouchedCallback: () => void = noop;

    constructor(private changeDetector: ChangeDetectorRef, public parentItem?: ItemComponent) {}

    /**
     * Input value getter.
     * (interface implementation)
     */
    get value(): any {
        return this._value;
    }

    /**
     * When input value is changed (setter).
     * (interface implementation)
     */
    set value(v: any) {
        if (v !== this._value) {
            this._value = v;

            // interface implementation
            if (this.onChangeCallback) {
                this.onChangeCallback(v);
            }
        }
    }

    /**
     * This function is called by the forms API when the control status changes to
     * or from "DISABLED". Depending on the value, it should enable or disable the
     * appropriate DOM element.
     * (interface implementation)
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Select the entire value in the field.
     */
    public select(): void {
        this.element.nativeElement.select();
    }

    /**
     * Focus element.
     */
    public focus() {
        this.element.nativeElement.focus();
    }

    /**
     * Blur element.
     */
    public blur() {
        this.element.nativeElement.blur();
    }

    /** Scrolls page in way that element is in visible view. */
    public scrollIntoViewIfNeeded() {
        if (this.element.nativeElement.scrollIntoViewIfNeeded) {
            this.element.nativeElement.scrollIntoViewIfNeeded();
        }
    }

    focusWithClickPrevent(event: MouseEvent) {
        event.preventDefault();
        this.focus();
    }

    /**
     * Focus handler called from template on inner input.
     * @see Templates of descendants of this abstract class.
     */
    onInnerInputFocus(event: FocusEvent) {
        this.focused = true;
        this.focusEmitter.emit(event);

        if (this.parentItem) {
            this.parentItem.onInnerItemFocus();
        }
    }

    /**
     * Blur handler called from template on inner input.
     * @see Templates of descendants of this abstract class.
     */
    onInnerInputBlur(event: FocusEvent) {
        this.focused = false;

        // interface implementation
        if (this.onTouchedCallback) {
            this.onTouchedCallback();
        }

        this.blurEmitter.emit(event);

        if (this.parentItem) {
            this.parentItem.onInnerItemBlur(this.value);
        }
    }

    /**
     * Writes a new value to the element.
     * (interface implementation)
     */
    writeValue(value: any): void {
        if (value !== this._value) {
            this._value = value;
            this.changeDetector.detectChanges();

            if (this.parentItem) {
                this.parentItem.onInnerItemChangeValue(this.value);
            }
        }
    }

    /**
     * Registers a callback function that should be called when the control's value
     * changes in the UI.
     * (interface implementation)
     */
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    /**
     * Registers a callback function that should be called when the control receives
     * a blur event.
     * (interface implementation)
     */
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }
}

export const GENERIC_INPUT_TOKEN = new InjectionToken('GenericInputComponent');
