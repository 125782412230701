import { Injectable } from '@angular/core';
import { MessageActions } from '../../../shared/types/message.types';

@Injectable({
    providedIn: 'root',
})
export class MessageSenderService {
    constructor() {}

    post(message: MessageActions.Actions) {
        window.parent.postMessage(
            {
                origin: 'stylers-cloud-client',
                ...message,
            },
            '*',
        );
    }
}
