import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'ui-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class LogoComponent implements OnInit {
    @Input()
    logo = true;

    @Input()
    text = true;

    constructor() {}

    ngOnInit() {}
}
