<ui-env></ui-env>
<ui-page-header [backButton]="backButton">
  <app-ui-back-button
    back-button
    *ngIf="backButton"
    [path]="backButtonPath"
    [url]="backButtonUrl"
  ></app-ui-back-button>
  <ng-content></ng-content>
  <ng-container icons>
    <app-ui-icon-button
      *ngIf="settingsButton"
      [icon]="'settings-sharp'"
      class="small"
      title="settings"
      navigateForward="/settings"
    ></app-ui-icon-button>
    <app-ui-close-button
      *ngIf="closeButton"
      [ignoreClick]="!!customCloseAction.observers.length"
      (click)="close($event)"
      title="{{ settingsButton ? 'Close app window.\n(you can close this app\nalso by hitting Esc key)' : ''}}"
    ></app-ui-close-button>
  </ng-container>
</ui-page-header>