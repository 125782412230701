<ui-page-header>
    <ui-back-button
        back-button
        (click)="close()"
    ></ui-back-button>
    <h1>Confirm</h1>
</ui-page-header>
<ui-page-content>
    <ui-text class="extra-padding pre-wrap" align="center">{{ question }}</ui-text>
    <ui-text
        *ngIf="options.description"
        class="extra-padding-after secondary small pre-wrap"
        align="center"
    >{{ options.description }}</ui-text>
</ui-page-content>
<ui-page-footer class="narrow">
    <ui-layout>
        <ui-button priority="secondary" (click)="no()">No</ui-button>
        <ui-button
            [priority]="options.negativeAction ? 'negative' : 'primary'"
            (click)="yes()"
        >
            Yes</ui-button>
    </ui-layout>
</ui-page-footer>
