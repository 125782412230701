import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-close-button',
    templateUrl: './close-button.component.html',
    styleUrls: ['./close-button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class CloseButtonComponent implements OnInit {
    @Input() emboss = true;

    constructor() {}

    ngOnInit() {}
}
