import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    HostBinding,
    Optional,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    HostListener,
    NgZone,
    forwardRef,
    AfterContentChecked,
} from '@angular/core';
import { ItemComponent } from '../item/item.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputComponent } from '../input/generic-input-component';

const textInputAsControlValueAccessorProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleComponent),
    multi: true,
};

@Component({
    selector: 'ui-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.sass'],
    encapsulation: ViewEncapsulation.None,
    providers: [textInputAsControlValueAccessorProvider],
})
export class ToggleComponent extends GenericInputComponent implements OnInit, AfterContentChecked {
    @ViewChild('formInput', { static: true })
    element!: ElementRef;

    @HostBinding('class.ui-form-item') formItem = true;

    @HostBinding('class.internal--checked')
    @Input()
    private checked = false;

    @HostBinding('attr.role') role = 'checkbox';
    @HostBinding('attr.tabindex') tabIndex = 0;

    constructor(
        changeDetector: ChangeDetectorRef,
        private componentElement: ElementRef,
        private zone: NgZone,
        @Optional() public parentItem?: ItemComponent,
    ) {
        super(changeDetector, parentItem);
    }

    ngOnInit() {}

    @HostListener('click', ['$event'])
    private _onClickHandler(event: MouseEvent) {
        if (!this.disabled) {
            this.checked = !this.checked;
            this.value = !this.value;
        }
    }

    ngAfterContentChecked() {
        this.checked = !!this.value;
    }

    @HostListener('mousedown', ['$event'])
    private _onMouseDownHandler(event: MouseEvent) {
        this.zone.runOutsideAngular(() => {
            this.componentElement.nativeElement.classList.add('internal--pressed');
        });
    }

    @HostListener('mouseup', ['$event'])
    @HostListener('mouseleave', ['$event'])
    private _onMouseReleaseHandler(event: MouseEvent) {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.componentElement.nativeElement) {
                    this.componentElement.nativeElement.classList.remove('internal--pressed');
                }
            }, 250);
        });
    }

    check() {
        this.value = true;
    }

    uncheck() {
        this.value = false;
    }
}
