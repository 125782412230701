import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppPath, NavigateService } from '../../services/navigate.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-ui-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ClientPageHeaderComponent implements OnInit {
    @Input()
    backButton = false;

    // Temp solution
    @Input() backButtonPath: AppPath;
    @Input() backButtonUrl: string;

    @Input()
    settingsButton = true;

    @Input()
    closeButton = true;

    @Output()
    customCloseAction = new EventEmitter<MouseEvent>();

    loggedIn$: Observable<boolean>;

    constructor(private authService: AuthService, public el: ElementRef) {}

    ngOnInit() {
        this.loggedIn$ = this.authService.isLoggedIn();
    }


    close($event: MouseEvent) {
        this.customCloseAction.emit($event);
    }
}
