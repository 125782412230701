import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class BadgeComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
