import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppPath, NavigateService } from 'src/app/services/navigate.service';
import { IconButtonComponent } from '../../ui/components/icon-button/icon-button.component';

@Component({
    selector: 'app-ui-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ClientIconButtonComponent implements OnInit {
    @Input() navigateRoot: string;
    @Input() navigateForward: string;
    @Input() icon: string;
    @Input() disabled: any;
    @Input() class: string;

    @ViewChild(IconButtonComponent) iconButton: IconButtonComponent;

    constructor(private navigate: NavigateService) {}

    ngOnInit() {}

    onBtnClick() {
        if (!this.iconButton.disabled) {
            setTimeout(() => {
                if (this.navigateRoot) {
                    this.navigate.to(this.navigateRoot as AppPath);
                } else if (this.navigateForward) {
                    this.navigate.to(this.navigateForward as AppPath);
                }
            }, 0 * 100); // to delay button animation (on mobile device - because performance)
        }
    }
}
