import { Injectable } from '@angular/core';
import { MessageSenderService } from './message-sender.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { filter, pluck, take } from 'rxjs/operators';
import { Project, ProjectDetail } from '../../../shared/types/project.types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { LastModifiedMeta, ListMeta, Response } from '../../../shared/types/http-response.types';
import { ActionTypes } from '../../../shared/types/message.types';
import { AppMode, UISize, WindowPosition } from '../../../shared/types/app.types';
import { environment } from '../../environments/environment';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SDKConfigType } from '../../../shared/types/sdk';

@Injectable({
    providedIn: 'root',
})
export class ProjectService implements Resolve<ProjectDetail> {
    // window.parent === window - development purposes
    projectSubject =
        window.parent === window
            ? new BehaviorSubject<ProjectDetail>({
                  id: '695c86e0-e897-11ec-8462-4f7d8b282e57', // WARNING - use (commit) only DEV or STAGE projects!!!
                  name: '---DEV-PROJECT---',
              } as ProjectDetail)
            : new BehaviorSubject<ProjectDetail>(null);

    project$ = this.projectSubject.asObservable();

    // in dev mode read fixed project id above ^
    private projectId: string = this.projectSubject.getValue()?.id || undefined;

    // Domain where app is being initialized
    initDomain: string;

    // window.parent === window - development purposes
    public currentUrl = window.parent === window ? 'www.example.com' : '';

    urlPattern = '';

    constructor(
        private message: MessageSenderService,
        private http: HttpClient,
        private configService: ConfigService,
    ) {}

    // turnOffOriginalStyles
    private _turnOffOriginalStyles = false;
    public get turnOffOriginalStyles() {
        return this._turnOffOriginalStyles;
    }
    public set turnOffOriginalStyles(val: boolean) {
        const changedValue = this._turnOffOriginalStyles !== val;
        this._turnOffOriginalStyles = val;
        if (changedValue) {
            if (!this.updatingConfig) {
                this.message.post({
                    action: this._turnOffOriginalStyles
                        ? ActionTypes.turnOffOriginalStyles
                        : ActionTypes.turnOnOriginalStyles,
                });
            }

            if (!this._turnOffOriginalStyles) {
                this.appMode = 'real';
            }
        }
    }

    private _windowPosition: WindowPosition = 'top-right';
    public get windowPosition() {
        return this._windowPosition;
    }
    public set windowPosition(val: WindowPosition) {
        const changedValue = this._windowPosition !== val;
        this._windowPosition = val;
        if (!this.updatingConfig && changedValue) {
            this.message.post({ action: ActionTypes.appPosition, position: this._windowPosition });
        }
    }

    private _uiSize: UISize = 'medium';
    public get uiSize() {
        return this._uiSize;
    }
    public set uiSize(val: UISize) {
        const changedValue = this._uiSize !== val;
        this._uiSize = val;
        document.documentElement.classList.remove('size-small');
        document.documentElement.classList.remove('size-medium');
        document.documentElement.classList.remove('size-large');
        document.documentElement.classList.add('size-' + this._uiSize);
        if (!this.updatingConfig && changedValue) {
            this.message.post({ action: ActionTypes.appSize, size: this._uiSize });
        }
    }

    private _appMode: AppMode = 'real';
    get appMode() {
        return this._appMode;
    }
    set appMode(val: AppMode) {
        const changedValue = this._appMode !== val;
        this._appMode = val;
        if (changedValue) {
            if (this._appMode === 'demo') {
                this.turnOffOriginalStyles = true;
            }
            if (!this.updatingConfig) {
                this.message.post({ action: ActionTypes.appMode, mode: this.appMode });
            }
        }
    }

    // to prevent cycling
    private updatingConfig = false;

    // from SDK to app
    updateConfig(config: SDKConfigType) {
        this.updatingConfig = true;

        this.configService.updateConfig(config);
        if (undefined !== config.turnOffOriginalStyles) {
            this._turnOffOriginalStyles = config.turnOffOriginalStyles;
        }
        if (undefined !== config.url) {
            this.urlPattern = config.url;
        }
        if (undefined !== config.appPosition) {
            this.windowPosition = config.appPosition;
        }
        if (undefined !== config.appSize) {
            this.uiSize = config.appSize;
        }
        if (undefined !== config.appMode) {
            this.appMode = config.appMode;
        }
        if (undefined !== config.currentUrl) {
            this.currentUrl = config.currentUrl;
        }
        if (undefined !== config.projectId && config.projectId !== this.projectId) {
            this.projectId = config.projectId;
            this.setProject(config.projectId);
        }
        if (undefined !== config.domain) {
            this.initDomain = config.domain;
        }

        this.updatingConfig = false;
    }

    isLocalhostDev() {
        return window === window.parent && location.hostname === 'localhost';
    }

    setProject(projectId: string) {
        this.getDetail(projectId)
            .pipe(take(1))
            .subscribe(project => {
                this.projectId = project.id;
                this.projectSubject.next(project);
            });
    }

    refreshProject() {
        this.setProject(this.projectId);
    }

    getProjectId() {
        return this.projectSubject.getValue().id;
    }

    getProjectRate() {
        return this.projectSubject.getValue().rate;
    }

    create(name: string, domain: string, force = false) {
        let params = new HttpParams();

        if (force) {
            params = params.append('forceCreate', '');
        }

        return this.http.post<Response<{ project: Pick<Project, 'id'> }>>(
            `${environment.apiUrl}v1/projects`,
            { name, domain },
            { params },
        );
    }

    get(extras: string[]) {
        return this.http
            .get<Response<Project[], ListMeta>>(`${environment.apiUrl}v1/projects?extra=${extras.join('')}`)
            .pipe(pluck('data'));
    }

    getDetail(id: string) {
        return this.http
            .get<Response<ProjectDetail, LastModifiedMeta>>(`${environment.apiUrl}v1/projects/${id}`)
            .pipe(pluck('data'));
    }

    update(id: string, project: Partial<ProjectDetail>) {
        return this.http.patch<void>(`${environment.apiUrl}v1/projects/${id}`, project);
    }

    delete(id: string) {
        return this.http.delete<void>(`${environment.apiUrl}v1/projects/${id}`);
    }

    addDomain(id: string, domain: string) {
        return this.http.post<void>(`${environment.apiUrl}v1/projects/${id}/domains`, { domain });
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): ProjectDetail | Observable<ProjectDetail> | Promise<ProjectDetail> {
        return this.project$.pipe(
            filter(project => !!project),
            take(1),
        );
    }
}
