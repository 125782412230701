import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';

@Component({
    selector: 'ui-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit {
    constructor(public elementRef: ElementRef) {}

    ngOnInit() {}
}
