import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmWindowOptions } from './confirm-window.service';

@Component({
    selector: 'ui-confirm-window',
    templateUrl: './confirm-window.component.html',
    styleUrls: ['./confirm-window.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmWindowComponent implements OnInit {
    @Input() question: string;

    @Input() options: ConfirmWindowOptions;

    constructor(public modalCtrl: ModalController) {}

    ngOnInit() {}

    close() {
        this.modalCtrl.dismiss();
    }

    yes() {
        this.modalCtrl.dismiss(true);
    }

    no() {
        this.modalCtrl.dismiss(false);
    }
}
